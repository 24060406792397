<template>
	<div>
		<headertop/>
		<div class="logoheader member">
			<header id="login">
				<div class="pwidth flex-r">
					<!-- <div class="w-200 h-80 tc flex m-t-15"><img class="w100 m-a" :src="$store.state.app.logourl" alt="" /></div> -->
					<div class="">
						<h1 class="f-24 blod-n w-160 h-70 tc flex m-t-15 m-r-20" @click="$router.push({path:'/index'})"><img class="h100 m-a" style="width: auto;" :src="$store.state.app.logourl" alt=""></h1>
						<h1>会员中心</h1>
					</div>
					<div class="w-120 h-40 b-a-00b absolute rad-2 l-h-40 c-00b tc r-0 t-30  cursor" @click="()=>{$router.push({path:'/cart'})}">
						<el-badge :value="$store.state.app.cartnumber" class="item ">
						  <i class="b2b-gouwuche iconfont m-r-8 f-20" ></i> 
						</el-badge>
						{{$store.state.app.Operational.header.cart}}
					</div>
				</div>
			</header>
		
			<section class="content m-t-10 pwidth m-b-40">
				<div class="w-170 bg-f" id="member" v-cloak>
					<el-row class="tac c-9">
						<el-col :span="24">
							<el-menu :default-active="defaultactive"
								class="el-menu-vertical-demo"
								@select="menuselect"
								:default-openeds="openeds" @open="handleOpen">
								<el-submenu index="1">
								<!-- 	<template slot="title">
										<span class="iconfont b2b-gerenxinxi c-6 f-20 m-r-5"></span>
										<span class="c-3 bold">个人信息</span>
									</template> -->
									<el-menu-item-group>
										<el-menu-item index="1-1"><i class="b2b-gerenzhongxin iconfont f-20 c-6" ></i> 个人中心</el-menu-item>
										<el-menu-item index="1-2"><i class="b2b-wodeziliao iconfont f-20 c-6" ></i> 我的资料</el-menu-item>
										<el-menu-item index="1-4"><i class="b2b-wodeshoucang1 iconfont f-20 c-6" ></i> 我的收藏</el-menu-item>
										<el-menu-item index="1-5"><i class="b2b-dizhiguanli iconfont f-20 c-6" ></i> 地址管理</el-menu-item>
										<el-menu-item index="2-2"><i class="b2b-tousujianyi iconfont f-20 c-6" ></i> 投诉建议</el-menu-item>
									</el-menu-item-group>
								</el-submenu>
		
								<!-- <el-submenu index="2">
									<template slot="title">
										<i class="icons p-l-5"><img src="../../assets/img/nv-2.png" alt=""></i>
										<span class="c-3 bold" v-if="$store.state.app.Operational">{{$store.state.app.Operational.member.aftersaleservice}}</span>
									</template>
									<el-menu-item-group>
										<el-menu-item index="2-1" v-if="$store.state.app.Operational">{{$store.state.app.Operational.member.Ordermanagement}}</el-menu-item>
										<el-menu-item index="2-2">投诉建议</el-menu-item>
									</el-menu-item-group>
								</el-submenu> -->
							</el-menu>
						</el-col>
					</el-row>
				</div>
				 <div class="member-right">
					 <index v-if="menuitemAactive=='1-1'"/>
					 <myinfo v-if="menuitemAactive=='1-2'"/>
					 <collection v-if="menuitemAactive=='1-4'"/>
					 <addressm v-if="menuitemAactive=='1-5'"/>
					 <order v-if="menuitemAactive=='2-1'"/>
					 <advise v-if="menuitemAactive=='2-2'"/>
				 </div> 
			</section>
		</div>
		<footers/>
	</div>
</template>

<script>

	var ismainadmin = sessionStorage.getItem('ismainadmin');
	var openid = sessionStorage.getItem('openid');
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	import collection from "./collection.vue"
	import myinfo from "./myinfo.vue"
	import addressm from "./address.vue"
	import index from "./index.vue"
	import order from "./order.vue"
	import advise from "./advise.vue"
	import invoices from './invoices.vue';
	export default{
		components:{footers,headertop,headers,collection,myinfo,addressm,index,order,advise,invoices},
		data() {
			ismainadmin = sessionStorage.getItem('ismainadmin');
			openid = sessionStorage.getItem('openid');
			return {
				// 侧边栏---------------------------------------
				openeds: ['1', '2', '3', '4'],
				uniqueOpened: false,
				menuitemAactive: '1-1',//当前点击的子菜单
				defaultactive: '1-1',//激活状态
				ismainadmin: ismainadmin,//是否管理员
			};
		},
		watch: {},
		created: function created() {
			var token = sessionStorage.getItem('token');
			if(!token)this.$router.push({path:'/login'})
			this.getTenantid(()=> {
				this.getRichtext()
			})
			this.menuitemAactive=this.defaultactive=sessionStorage.getItem('defaultactive')||'1-1'
			if(this.$route.query.key)this.defaultactive=this.menuitemAactive=this.$route.query.key
			
		},
		methods: {
			
			// 展开收缩
			handleOpen: function handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			
			// 点击子菜单
			menuselect: function menuselect(key, keyPath) {
				this.menuitemAactive = keyPath[1];
				this.defaultactive = key;
				sessionStorage.setItem('defaultactive',key)
				console.log( keyPath[1])	
		// 		membermans.menuitemAactive = key;
		// 		maninfo.menuitemAactive = key;
		// 		mandata.menuitemAactive = key; // membermineNews.menuitemAactive = key
		
		// 		collection.menuitemAactive = key;
		// 		memberBack.menuitemAactive = key;
		// 		aproposal.menuitemAactive = key; // memberPurchasing.menuitemAactive = key
		// 		// membercontract.menuitemAactive = key
		// 		// memberSetmeal.menuitemAactive = key
		// 		// memberbill.menuitemAactive = key
		
		// 		switch (this.menuitemAactive) {
		// 			case '1-1':
		// 				maninfo.orderinfoactive = false;
		// 				maninfo.orderlist(); // maninfo.mynewslist()
		
		// 				maninfo.getnumber();
		// 				maninfo.Logisticsactive = false;
		// 				maninfo.iconpath = sessionStorage.getItem('iconpath');
		// 				break;
		
		// 			case '1-2':
		// 				mandata.getmandata();
		// 				break;
		
		// 			case '1-3':
		// 				// membermineNews.newlist(); //消息
		// 				break;
		
		// 			case '1-4':
		// 				collection.inquire();
		// 				break;
		
		// 			case '1-5':
		// 				// membermans.getuserFN()
		// 				// membermans.manage.newSubuser = false
		// 				// membermans.getcitycode()
		// 				membermans.getquerymycustomer();
		// 				break;
		
		// 			case '3-4':
		// 				memberbill.caigoubill();
		// 				break;
		
		// 			case '2-1':
		// 				memberBack.alllist(); // memberBack.backlist()
		// 				// memberBack.laiyuanyiyuanFN()
		
		// 				if (memberBack.alldetail) {
		// 					memberBack.alldetail = false;
		// 					memberBack.backviewshow = false;
		// 				} else {
		// 					if (memberBack.backviewshow) memberBack.backviewshow = false;
		// 				}
		
		// 				break;
		
					
		// 		}
			}
		}
	}
	
</script>

<style>
	.el-menu-item{color: #666;}
	.el-menu-item.is-active{font-weight: bold;background:var(--selectedColor,'#00b14e');color:#fff;}
	.el-submenu__title{display: none;}
	.el-menu-item.is-active .iconfont{font-weight: 100 !important;}
	.el-menu{border: none;}
	.el-submenu .el-menu-item{ min-width:unset !important;width: 130px;border-radius: 50px; padding: 0 !important;margin:10px auto ;text-align: center;height: 36px;line-height: 36px;}
</style>
