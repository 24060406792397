<template>
	
	<!-- 退货申请页面 -->
	<div class="memberSetmeal memberorderinfo">
	<div class=" backdetail ">
	    <div class="bg-f arrow p-20-20 relative">
	        <el-breadcrumb separator-class="el-icon-arrow-right">
	            <el-breadcrumb-item>你所在的位置：会员中心</el-breadcrumb-item>
	            <el-breadcrumb-item> <span @click="$emit('ret',1)"  class="cursor">订单管理</span></el-breadcrumb-item>
	            <el-breadcrumb-item>申请退货</el-breadcrumb-item>
	        </el-breadcrumb>
	        <span class="cursor c-00b fanhui" @click="$emit('ret',1)">返回</span>
	    </div>
		
	    <div class="m-t-10 backdetail-c  bg-f relative  checkret">
	        <p class="bg-f p-0-20 title border-b flex"><span> 订单编号：{{detail.tradeid}} </span></p>
	        <template>
	            <el-table
	                stripe
	                ref="multipleTable"
	                :data="alllistdetailArr"
	                tooltip-effect="dark"
	                style="padding: 0 7px;width: auto;"
	                @selection-change="handleSelectionChange">
	                <el-table-column type="selection" width="55"> </el-table-column>
	                <el-table-column prop="goodsid" label="商品编号" width="80"></el-table-column>
	                <el-table-column prop="goodsname" label="通用名称"> </el-table-column>
	                <el-table-column prop="batchno" label="批号" width="100"> </el-table-column>
	                <el-table-column prop="approvalno" label="批准文号"> </el-table-column>
	                <el-table-column prop="spec" label="产品规格" width="" show-overflow-tooltip> </el-table-column>
	                <el-table-column prop="qty" label="采购数量" width="100" show-overflow-tooltip> </el-table-column>
		
	                <el-table-column label="可退货数量" class="tc" width="100">
	                    <template slot-scope="scope">
	                        <div class="inputnumber ">
	                            <input @change="retchange(scope.row)"  class="border-a w-60"
	                                :disabled="scope.row.retqtys==0"
	                                v-model="scope.row.retqtys"
	                                max="scope.row.retqtys"
	                                value="scope.row.retqtys"
	                                type="text"
	                                oninput="if(value.toString().indexOf('-')){
	                                value = parseInt(value.slice(0,9))||1
	                                }else if(value<0){ value=parseInt(value.slice(1, 4))}
	                                ">
	                        </div>
	                        <div class=" h-14 w-14 disabcheck c-9" v-show="scope.row.retqtys==0" @click="disabcheck">
	                        </div>
	                    </template>
	                </el-table-column>
	            </el-table>
	        </template>
		
	       
			
			<template>
				<div class="pagination tc" style="margin: 10px 0 10px;">
					<div id="Pagination">
						<el-pagination background layout="prev, pager, next ,total,jumper" 
							@current-change="memberBackhandleCurrentChange"
							:current-page="pageno" :total="total">
						</el-pagination>
					</div>
				</div>
			</template>
			
			
	    </div>
		
	    <div class="shenqing m-t-20 h-50 l-h-50 bg-f">
	        <p class="m-l-20"></p>
	        <div class="flex">
	            <p class="m-r-20 p-l-20 ">已选商品<span class="c-ff8 ">{{retgoods.length}}</span> 件</p>
	            <p class=" cursor h-50 bg-00B c-f w-100 tc" @click="retbtm(detail.tradeid)">提交退货商品</p>
	        </div>
	    </div>
	</div>
	</div>
</template>

<script>
	var openid= sessionStorage.getItem('openid');
	export default {
		data(){
			openid= sessionStorage.getItem('openid');
			return{
				pageno:1,
				retgoods: [],					//退货选中的商品
				alllistdetailArr:[],
				total:0,
				openid:openid,
				retgoods:''
			}
		},
		props:['detail'],
		
		created() {
			this.getlookinfo()
		},
		
		methods:{
			memberBackhandleCurrentChange(tab){
				this.pageno = tab;
			},
			
			// 退货申请页面选中的商品
			handleSelectionChange: function handleSelectionChange(e) {
				console.log(e);
				this.retgoods = e;
				for (var i in this.retgoods) {
					if (this.retgoods[i].retqtys == 0) {
						this.retgoods.splice(i, 1);
					}
				}
			},
			
			// 退货商品禁止选择
			disabcheck: function disabcheck() {
				this.$notify({title: '成功',message: '当前商品不可选择',type: 'error'});
			},
			
			// 退货数量限制
			retchange: function retchange(scope) {
				console.log(scope);
				console.log(this.alllistdetailArr);
					
				for (var i in this.alllistdetailArr) {
					console.log(scope.retqtys, scope.qty);
					
					if (this.alllistdetailArr[i].goodsid == scope.goodsid) {
						if (parseInt(scope.retqtys) > scope.qty) {
							this.alllistdetailArr[i].retqtys = scope.qty - scope.retqty;
						}
					}
				}
			},
			
			// 查看订单详情
			getlookinfo: function getlookinfo(type) {
				console.log('查看详情',this.detail);
				this.isreturnprv = type;
				var self = this;
				var Url = '/trade/info';
				var data = {
					tradeid: this.detail.tradeid,
					openid: openid,
					pageno: this.orderPage,
					pagesize: this.pagesize,
					checkBatch:1,
				};
			
				this.requset(Url, data).then(function(data) {
						if (data.status == 'success') {
							document.documentElement.scrollTop = 0;
							var result = data.data.rows;
							for (var i in result) {
								result[i].batchno = result[i].batchno != 'defaultbno' ? result[i].batchno : '默认分配';
							}
							self.alllistdetailArr = result;
							self.tableDatadetailtotal = data.data.total;
							self.tradeinfo = data.data.tradeinfo;
						
						}
					});
				
			},
			
			
			// 退货提交
			retbtm: function retbtm(tradeid) {
				var _this = this;
				if (!this.retgoods.length) {
					this.$notify({title: '提示',message: '请选择商品',type: 'error',duration:1000});
					return;
				}
					
				var retgoodssend = [];
					
				for (var i in this.retgoods) {
					
					if (!this.retgoods[i].retqtys) {
						this.$message.error('退货数量不能为空');
						return;
					}
					
					var objgoods = {
						goodsid: this.retgoods[i].goodsid,
						goodsno: this.retgoods[i].goodsid,
						tradegoodsid: this.retgoods[i].tradegoodsid || 0,
						qty: this.retgoods[i].retqtys,
						batchno: this.retgoods[i].batchno == "默认分配" ? 'defaultbno' : this.retgoods[i].batchno
					};
					retgoodssend.push(objgoods);
				}
					
				console.log(objgoods);
				this.$prompt('请输入你的退货原因', '退货原因', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputType: 'textarea',
					inputPattern: /[\u4e00-\u9fffa-zA-Z\a-zA-Z\0-9\w]{1,100}$/,
					inputErrorMessage: '内容不能为空'
				}).then(function(_ref2) {
						console.log('_ref2',_ref2);
					var value = _ref2.value;
					var self = _this;
					var data = {
						tradeid: tradeid,
						message: value,
						reason: value,
						retgoods: retgoodssend
					};
					console.log(data);
					_this.requset('/b2bret/create', data,{met:'json'}).then(function(data) {
						self.$notify({title: '提示',message: data.message,type: 'warning',duration:2000});
						self.getlookinfo();
					}, function(err) {
						console.log(err);
					});
				}).catch(function(_ref2) {console.log('_ref2',_ref2);});
			},
			
			// // 查看退货单详情
			// retdetailgoods: function retdetailgoods(retid, tradeid, type, isreturnprv) {
			// 	console.log(this.detail)
			// 	var _this2 = this;
			// 	this.isreturnprv = isreturnprv;
			// 	this.retidnumber =this.detail.tradeid;
					
			// 	if (type == "look") {
			// 		this.alldetail = !this.alldetail;
			// 	} else {
			// 		this.backviewshow = !this.backviewshow;
			// 	}
					
			// 	if (this.currentPageshow)this.alldetail = true;
			
			
			// 	var data = {
			// 		retid:this.detail.tradeid,
			// 		tradeid: this.detail.tradeid,
			// 		openid: openid,
			// 		pageno: this.pageno,
			// 		pagesize: this.pagesize
			// 	};
			
			// 	this.requset('/b2bret/info', data).then(function(data) {
			// 			var result = data.data.list;
			// 			for (var i in result) {
			// 				result[i].batchcode = result[i].batchcode != 'defaultbno' ? result[i].batchcode : '默认分配';
			// 			}
			// 			_this2.alllistdetailArr = result;
			// 			_this2.total = data.data.total;
			// 			_this2.tradeinfo = data.data.tradeInfo;
			// 			_this2.retinfo = data.data.retinfo;
			// 	});
			// },
		}
		
		
	}
	
	
	
</script>

<style>
</style>
