<template>
	<!--地址管理 -->
	<div class="mandata" id="membermans">
		<div class="bg-f p-l-20 title flex p-r-20 ">
			<h3 class="f-18 bold">
				<i class="shu m-r-5"></i>
				收货地址
			</h3>
			<span class="newnumber tc cursor c-ff8" @click="addressbtn(0)">新增收货地址</span>
		</div>   

		<!-- 业务员地址列表 -->
		<div class="manList bg-f m-t-10 min-h-290" >
			<div class="p-20 p-t-30">
				<div class="border-a-e p-20  relative">
					<h3 class="absolute t--12 p-0-12 bg-f l-9 f-14 bold">收货公司：{{ companyname }}</h3>
					<ul>
						<li class="flex start m-b-10 cursor" v-for="(item, index) in addresslist">
							<div class=" bg-f5 w-100 h-30 l-h-30 m-r-40 tc relative">{{ item.receiptname }}</div>
							<div class="l-h-30 flex  w100">
								<p>
									<span class="m-r-10">{{ item.phone }}</span>
									<span class="m-r-10">{{ item.areadetail }}{{ item.address }}</span>
									<!--     <span class="inline bg-9 c-f rad-4 f-12 h-20 w-55 l-h-20 tc m-l-10"
	                                    v-if="item.isdefault">默认地址</span> -->
								</p>
								<p class="c-00b" @click="addressbtn(item)"><i class="iconfont">&#xe69a;</i>编辑</p>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="" v-if="querymycustomer.lenght">暂无数据</div>
		</div>

		<!-- 添加收货地址 -->
		<div class="alert addressdialog">
			<el-dialog :title="title" :visible.sync="dialogTableVisible">
				<div class="content">
					<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="收货公司" class="relative">
							<div class="p-l-10 f-14 bold">{{ companyname }}</div>
						</el-form-item>

						<el-form-item label="收货人" prop="name" class="relative w-540">
							<el-input type="text" v-model="ruleForm.name"  maxlength="6" autocomplete="off" placeholder="请输入姓名"></el-input>
						</el-form-item>

						<el-form-item label="手机号码" prop="phone" class="relative w-540">
							<el-input type="text" v-model="ruleForm.phone" maxlength="11" autocomplete="off" placeholder="请输入电话"></el-input>
						</el-form-item>

						<el-form-item label="所在区域" class="quyu  w-540" prop="shengid">
							<el-cascader :options="province" :props="provinceProps" placeholder="请选择省份" v-model="ruleForm.shengid" @change="handleChangesheng"></el-cascader>
							<el-cascader :options="city" placeholder="请选择市" :props="provinceProps" v-model="ruleForm.cityid" @change="handleChangecity"></el-cascader>
							<el-cascader :options="area" placeholder="请选择区域" :props="provinceProps" v-model="ruleForm.areaid" @change="handleChangearea"></el-cascader>
						</el-form-item>

						<el-form-item label="详细地址" prop="addressinfo" class="relative w-540">
							<el-input class="addressinfo" type="text" v-model="ruleForm.addressinfo" autocomplete="off" placeholder="请输入您的详细地址"></el-input>
						</el-form-item>

						<!-- <el-form-item> -->
						<div class="btn p-l-20">
							<div class="saveaddress tc cursor flex m-t-20 p-0-100">
								<el-popover v-if="ruleForm.id" placement="top" width="160" v-model="visible">
									<p>确定删除这地址吗？</p>
									<div style="text-align: right; margin: 0">
										<el-button size="mini" type="text" @click="visible = false">取消</el-button>
										<el-button type="primary" size="mini" @click="deladdress(ruleForm.id)">确定</el-button>
									</div>
									<el-button slot="reference" class="deladdress p-0 m-r-20 f-14 c-6">删除</el-button>
								</el-popover>
								<p class="w-100 tc f-20 sava f-14" @click="updateaddress('ruleForm', 2)">保存</p>
							</div>
						</div>
						<!-- </el-form-item> -->
					</el-form>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	
	var salesmannum = sessionStorage.getItem('salesmannum');
	var issaleman = sessionStorage.getItem('issaleman'); //0客户 1业务员
	var companyname = sessionStorage.getItem('companyname'); //0客户公司名称
	var companycode = sessionStorage.getItem('companycode'); //公司编码
	var erpcustno = sessionStorage.getItem('erpcustno'); //
	var province = sessionStorage.getItem('province'); //省份
export default {
	data(){
		salesmannum = sessionStorage.getItem('salesmannum');
		issaleman = sessionStorage.getItem('issaleman'); //0客户 1业务员
		companyname = sessionStorage.getItem('companyname'); //0客户公司名称
		companycode = sessionStorage.getItem('companycode'); //公司编码
		erpcustno = sessionStorage.getItem('erpcustno'); //
		province = sessionStorage.getItem('province'); //省份
		var _this = this
		var checkcompany = function checkcompany(rule, value, callback) {
			if (!value) {
				return callback(new Error('请选择公司'));
			} else {					callback();				}
		};

		var checkname = function checkname(rule, value, callback) {
			if (!value) {
				return callback(new Error('收货人不能为空'));
			} else {					callback();				}
		};

		var checkshengid = function checkshengid(rule, value, callback) {
			if (!value) {
				return callback(new Error('所在省不能为空'));
			} else {
				callback();
			}
		};

		var checkcityid = function checkcityid(rule, value, callback) {
			if (!value) {
				return callback(new Error('姓名不能为空'));
			} else {
				callback();
			}
		};

		var checkareaid = function checkareaid(rule, value, callback) {
			if (!value) {
				return callback(new Error('姓名不能为空'));
			} else {
				callback();
			}
		};

		var checkaddressinfo = function checkaddressinfo(rule, value, callback) {
			if (!value) {
				return callback(new Error('请输入详细地址'));
			} else {
				callback();
			}
		};

		var checkphone = function checkphone(rule, value, callback) {
			if (!value) {
				_this.disabled = false;
				return callback(new Error('手机号不能为空'));
			}
			setTimeout(function() {
				if (!/[1]([3-9])[0-9]{9}$/.test(value)) {
					callback(new Error('手机号格式不正确'));
					_this.disabled = false;
				} else {
					_this.disabled = true;
					callback();
				}
			}, 1000);
		};

		return{
			dialogTableVisible: false,				//是否显示添加地址弹窗
			// 新增地址参数
			ruleForm: {
				id: '',
				company: '',
				name: '',
				phone: '',
				shengid: '',
				cityid: '',
				areaid: '',
				addressinfo: '',
				isdefault: false
			},
			// 验证规则
			rules: {
				// company: [{ required: true, validator: checkcompany, trigger: 'change' }],
				name: [{required: true,validator: checkname,trigger: 'blur'}],
				phone: [{required: true,validator: checkphone,trigger: 'blur'}],
				shengid: [{required: true,validator: checkshengid,trigger: 'change'}],
				cityid: [{validator: checkcityid,trigger: 'change'}],
				areaid: [{validator: checkareaid,trigger: 'change'}],
				addressinfo: [{required: true,validator: checkaddressinfo,trigger: 'blur'}]
			},
			optionProps: {value: 'companycode',label: 'companyname',children: 'childrens'},
			provinceProps: {value: 'id',label: 'name',children: 'childrens'},
			province: [],				//省
			city: [],				//市
			area: [],				//区
			addresslist: [],				//获取的地址列表
			provinceid: '',				//选择的省id
			cityid: '',				//选择的市id
			areaid: '',				//选择的区id
			provinceidname: '',				//选择的省name
			cityidname: '',				//选择的市name
			areaidname: '',				//选择的区name
			selectaddresskey: 0,				//选着的地址key
			selectpaytypekey: 0,
			querymycustomer: [{
				addresslist: []
			}],
			//获取的公司
			receiptcompanycode: '',
			visible:false,
			submissionaddress:false,
			issaleman:issaleman,
			companyname:companyname,
			companycode:companycode,
			title:''
		}
	},
	created: function created() { 
		// this.getuserFN()
		this.getcitycode()
		this.getquerymycustomer()
	},
	methods: {
		
		
		newSubuserFN: function newSubuserFN() {
			this.dialogTableVisible = !this.dialogTableVisible;
		},
		// 点击是否默认地址
		isdefaultbtn: function isdefaultbtn() {
			this.ruleForm.isdefault = !this.ruleForm.isdefault;
		},
		
		
		
		// 点击新增地址  /编辑地址
		addressbtn: function addressbtn(item) {
			this.dialogTableVisible = !this.dialogTableVisible;
			var province = sessionStorage.getItem('province'); //省份

			if (province)this.province = JSON.parse(province);
			if (!province) this.getcitycode();
			var province = this.province;
	
			if (item) {
				if(this.$refs['ruleForm'])this.$refs['ruleForm'].clearValidate();
	
				for (var i in province) {
					if (province[i].id == item.provinceid) {
						this.getcitycode(province[i].taobaoid, item.cityid, item.districtid);
						this.provinceidname = province[i].name; //选择的省name
					}
				}
	
				this.ruleForm = {
					id: item.id,
					company: item.b2bcompanycode,
					name: item.receiptname,
					phone: item.phone,
					shengid: item.provinceid,
					cityid: item.cityid,
					areaid: item.districtid,
					addressinfo: item.areadetail,
					isdefault: item.isdefault
				};
				this.provinceid = item.provinceid;
				this.cityid = item.cityid;
				this.districtid = item.districtid;
				this.title = '编辑收货地址';
			} else {
				this.empty();
			}
		},
		
		
		
		// 清空
		empty: function empty() {
			this.ruleForm = {
				id: '',
				company: '',
				name: '',
				phone: '',
				shengid: '',
				cityid: '',
				areaid: '',
				addressinfo: '',
				isdefault: false
			};
			this.provinceid = '';
			this.cityid = '';
			this.areaid = '';
			this.provinceidname = '';
			this.cityidname = '';
			this.areaidname = '';
			this.title = '添加收货地址';
			if (this.$refs['ruleForm']) this.$refs['ruleForm'].clearValidate();
		},
		
		
		// 保存地址POST /memberaddressfront/save
		saveaddredd: function saveaddredd() {
			var Url = '/memberaddressfront/save';
			var {ruleForm,querymycustomer,province,area,city,submissionaddress,provinceid,cityid,areaid,provinceidname,cityidname,areaidname,}=this
			var b2bcompanycode = typeof(ruleForm.company) == 'object' ? ruleForm.company[0] : ruleForm.company;
			if (submissionaddress) return;
			this.submissionaddress = true;
			var data = {
				id: ruleForm.id,
				receiptname: ruleForm.name,
				address: ruleForm.addressinfo,
				phone: ruleForm.phone,
				provinceid: provinceid,
				cityid: cityid,
				districtid: areaid,
				areadetail: provinceidname + cityidname + areaidname,
				isdefault: ruleForm.isdefault ? 1 : 0,
				b2bcompanycode: this.companycode
			};
			console.log(data);
			
				this.requset(Url, data).then(res=>{
					if (res.status == "success") {
						this.dialogTableVisible = false;
						this.submissionaddress = false;
						this.empty();
						this.$refs['ruleForm'].clearValidate(); // for (let i in this.querymycustomer) {
						this.getaddresslist(); // this.$refs['ruleForm'].clearValidate();
					}
				}).catch(function() {
					this.submissionaddress = false;
				});	
		},
		
		
		// 添加地址验证
		updateaddress: function updateaddress(formName, index) {
			var self = this;
			this.$refs[formName].validate(function(valid) {
				if (valid) {
					self.saveaddredd();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		//收货地址里面切换公司
		addresschangecompany: function addresschangecompany(value) {
			console.log(value);
			this.ruleForm.company = value;
		},
		
		
		// 当前是业务员时获取业务员客户 否则默认当前的客户公司
		getquerymycustomer: function getquerymycustomer() {

			
			this.getaddresslist();
		},
		
		
		
		// 获取收货地址列表POST /memberaddressfront/list
		getaddresslist: function getaddresslist() {
			var self = this;
			var Url = '/memberaddressfront/list';
			var querymycustomer = this.querymycustomer;
			var data = {
				pageno: 1,
				pagesize: 999999,
				b2bcompanycode: this.companycode
			};
			
			this.requset(Url, data).then(function(data) {
					self.addresslist = data.data.list;
				});
			
		
		},
		
		
		// 删除收货地址
		deladdress: function deladdress(id) {
			var querymycustomer = this.querymycustomer;
			var Url = '/memberaddressfront/del';
				this.requset(Url, {id: id}).then(res=> {
					if (res.status == "success") {
						this.dialogTableVisible = false;
						this.getquerymycustomer(); // for (let i in querymycustomer) {// }
						this.getaddresslist();
						this.empty();
					}
				});	
		},
		
		// 选择省
		handleChangesheng: function handleChangesheng(value) {
			var province = this.province;
	
			for (var i in province) {
				if (province[i].id == value[0]) {
					this.provinceid = province[i].id;
					this.provinceidname = province[i].name;
					this.getcitycode(province[i].taobaoid);
				}
			}
	
			this.cityid = '';
			this.cityidname = '';
			this.areaid = '';
			this.areaidname = '';
		},
		
		
		
		// 选择市
		handleChangecity: function handleChangecity(value) {
			var city = this.city;
	
			for (var i in city) {
				if (city[i].id == value[0]) {
					console.log(city[i]);
					this.cityid = city[i].id;
					this.cityidname = city[i].name;
					this.getarea(city[i].taobaoid);
				}
			}
		},
		
		
		
		// 选择区
		handleChangearea: function handleChangearea(value) {
			var area = this.area;
			console.log(value);
			for (var i in area) {
				if (area[i].id == value[0]) {
					this.areaid = area[i].id;
					this.areaidname = area[i].name;
					console.log(this.areaid);
					console.log(this.areaidname);
				}
			}
		},
		
		
		
		
		// 获取省市地址  // POST /memberfront/getcitycode
		getcitycode(code, cityid, areaid) {
			var code = code ? code : 1;
			var Url = '/memberfront/getcitycode';	
			this.requset(Url, {code: code}).then(res=>{
					if (res.status == "success") {
						var data = res.data;
						if (code == 1) {
							// 省
							this.province = data;
							sessionStorage.setItem('province', JSON.stringify(data)); 
						} else {
							// 市
							for (var i in data) {
								if (data[i].id == cityid) {
									this.getarea(data[i].taobaoid, areaid);
									this.cityidname = data[i].name; //选择的市name
								}
							}
				
							if (!cityid) {
								this.getarea(data[0].taobaoid);
								this.ruleForm.cityid = data[0].id;
								this.cityidname = data[0].name; //选择的市name
								this.cityid = data[0].id;
							}
							this.city = data;
						}
					}
				});	
				
		},
		
		
		
		// 获取的区域
		getarea(code, areaid) {

			var Url = '/memberfront/getcitycode';
			this.requset(Url, {code: code}).then((res)=>{
					if (res.status == "success") {
						var data = res.data;
						for (var i in data) {
							if (data[i].id == areaid)this.areaidname = data[i].name; //选择的区name
						}
						if (!areaid) {
							this.ruleForm.areaid = data[0].id;
							this.areaidname = data[0].name; //选择的区name
							this.areaid = data[0].id;
						}
						this.area = data;
					}
				});
		} 
	
	}

	
}
</script>

<style>
	.el-input--suffix ::placeholder {
	            color: #333;
	}
</style>
