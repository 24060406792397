<template>
	
	<!-- 订单详情 -->
	<div class="memberSetmeal memberorderinfo">

	    <!-- 查看详情 -->
	    <div class="detail">
	        <div class="location  p-20  f-16 bg-f  flex relative">
	            <el-breadcrumb separator-class="el-icon-arrow-right">
	                <el-breadcrumb-item> 你所在的位置：<i class="el-icon-s-home"></i>
	                    <span class="cursor" @click="$emit('ret',1)" >个人中心</span>
	                </el-breadcrumb-item>
	                <el-breadcrumb-item>订单详情</el-breadcrumb-item>
	                <el-breadcrumb-item >查看发票</el-breadcrumb-item>
	
	            </el-breadcrumb>
	            <span class="cursor c-00b fanhui" @click="$emit('ret',1)">返回</span>
	        </div>
	
	
	      
		
		   
			<div class="table m-t-10 bg-f p-10 ">
	            <template>
	                <el-table :data="invoicesdata" stripe style="width: 100%">
	                    <el-table-column prop="invoiceline" label="发票类型" >
	                        <template slot-scope="scope">
	                            <span class="ell">{{scope.row.company}}</span>
	                           
	                        </template>
	                    </el-table-column>
	                    <el-table-column prop="fpdm" label="发票代码" ></el-table-column>
	                    <el-table-column prop="hjse" label="开票金额" >
							<template slot-scope="scope">
							   <span>￥{{scope.row.hjse}}</span>
							</template>
						</el-table-column>
	                    <el-table-column prop="kprq" label="开票时间" ></el-table-column>
	                    <el-table-column prop="promotesales.salesname" label="操作" class="c-ff3">
	                        <template slot-scope="scope">
	                            <a :href="scope.row.pdfurl" target="_blank" class="c-00b">下载发票</a>
	                        </template>
	                    </el-table-column>
	                </el-table>
	            </template>

				<template>
					<div class="pagination tc" style="margin: 10px 0 10px;">
						<div id="Pagination">
							<el-pagination background layout="prev, pager, next ,total,jumper" 
								@current-change="memberBackdetal"
								:current-page="orderPage" :total="tableDatadetailtotal">
							</el-pagination>
						</div>
					</div>
				</template>
	        </div>
	
	    </div>
	</div>
</template>

<script>
	var companyname = sessionStorage.getItem('companyname');
	var openid= sessionStorage.getItem('openid');
	var companycode = sessionStorage.getItem('companycode');

	export default{
		data(){
			companyname = sessionStorage.getItem('companyname');
			openid= sessionStorage.getItem('openid');
			companycode = sessionStorage.getItem('companycode');
			return{
				companyname:companyname,
				companycode:companycode,
				openid:openid,
				tradeinfo: null, 
				orderPage: 1,
				pagesize:10,
				orderDotal: 10,
				tableDatadetailtotal:0,
				invoicesdata:[],
				retinfo: null,					//退货详情的收货地址信息
			}
		},
		props:['detail','active'],
		created() {
			this.getlookinfo()
		},
		
		
		
		methods:{
			// 查看订单详情
			getlookinfo: function getlookinfo(type) {
				console.log('查看详情',this.detail);
				this.isreturnprv = type;
				var self = this;
				var Url = '/trade/invoice/list';
				var data = {
					tradeid: this.detail.tradeid,
					openid: openid,
					pageno: this.orderPage,
					pagesize: this.pagesize,
				};

				this.requset(Url, data).then((data)=>{
						if (data.status == 'success') {
		
							this.invoicesdata = data.data;

							self.download();
						}
					});
				
			},
			
			
			

			// 详情商品分页
			memberBackdetal(tab, event) {
				this.orderPage = tab;
				this.getlookinfo();
			},
			
			// 下载批检报告
			download: function download() {
				this.downloadurl =this.downloadExcel('/trade/batchgoodsdata', this.detail.tradeid);
			},
			

			
			
			
		}
		
		
	}
	
</script>

<style>
	.el-table__empty-text{text-align: left;}
</style>
