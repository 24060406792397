<template>
	<!-- 我的收藏 -->
	
	<div class="Mycollection">
	    <!-- <h3 class="bg-f p-l-20 title m-b-10 f-16"><i class="shu m-r-5"></i> 我的收藏</h3> -->
	
	    <div class="title bg-f flex m-b-10 p-0-20">
	        <p class="c-6">
	            <!-- @click="allcheck" <i class="radio-m m-r-10 top-3" :class="{'radio':manage.allactive}"></i>全选 -->
	            <span class="c-6"><i class="shu m-r-5"></i><span class="c-3 f-18 bold">我的收藏</span><b class="c-ff8A f-16"> ({{total}})</b></span>
	        </p>
	
	        <p class="search relative flex">
	            <img class="absolute l-10 t-10 z-i-3" src="../../assets/img/search.png" alt="">
	            <!-- <input type="text" v-model="searchvalue" placeholder="请输入搜索内容"> -->
	            <el-input @keyup.enter.native="search(value)" v-model="searchvalue" class="c-3 h100" :value="searchvalue"
	                placeholder="请输入搜索内容"></el-input>
	            <span class="relative " @click="search">搜索</span>
	        </p>
	    </div>
	    <!-- 列表 -->
	    <ul class="searchList min-h-230 " ref="element">
	        <li>
	            <div class="p-b-10 bg-f m-b-10" v-for="(item,gkey) in inquireArr" :key="gkey" >
	                <!-- @click="ckeckitemfn(item)" -->
					<div class="list  relative ">
						<div class="goodsimg cursor" @click="gotodetail(item.id)">
							<i class="radio-m" :class="{'radio':item.ckeck}" @click.stop="ckeckgoods(item,item.batchInfoList)" v-if="(item.isvisible!=0)&&item.batchInfoList"> </i>
							<img v-if="item.imageurl" :src="item.imageurl"
								onerror="javascript:this.src='../../assets/img/mr.png';"
								style="width: 126px;height: 126px; ">
							<img v-if="!item.imageurl" src="../../assets/img/mr.png" alt="">
						</div>
						<img v-if="item.specialoffer==1" @click="gotodetail(item.id)"  class="absolute t-0 l-0" style="width: 35px;height: 40px;" src="../../assets/img/teja.png" >
						<div class="detail">
							<div class=" title">
								<h3 class="cursor" @click="gotodetail(item.id)">
									<!-- <span> {{item.goodsname}}</span><i>RX</i><i>医保品种</i> -->
									<span> {{item.goodsname}}</span>
									<i v-if="item.isprescription" class="f-12 relative t-0 h-20 l-h-16 i-b">{{item.isprescription? 'RX':'' }}</i>
									<i v-if="item.isephedrine">{{item.isephedrine? '麻黄碱':''}}</i>
								</h3>
								<div class="addcard cursor " @click="addCollection(item.id,1)">
									<i class="m-r-5 iconfont  b2b-yishoucang c-ffa f-16 relative "></i><span><span class="c-3">取消收藏</span></span>
								</div>
							</div>
							
							
							<div class="">
								<div class="sell i-b c-f p-0-10 f-12 m-r-10 relative o-h rad-40 " v-for="(cuxiao,cuxiaoindex) in item.promotesalesList" :key="cuxiaoindex">
									<img class="w-60 h-20 absolute l-10  l-0" src="../../assets/img/cx2.png" alt=""> 
									<span class="relative z-i-10"><span class="bold m-r-10">{{cuxiao.salesname}}</span>{{cuxiao.promoteTitle}}</span>
								</div>
							</div>
							
							
							<div class="describe relative p-r-20">
								<ul class="w100 bg-f9 p-5-10 l-h-26 ">
									<li>{{goodsview[$store.state.app.goodsvivewtype].tymc}}：{{item[goodsview[$store.state.app.goodsvivewtype].tymcval]}}</li>
									<li>
										<p class="ell w80">{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</p>
									</li>
									<li> {{goodsview[$store.state.app.goodsvivewtype].xh}}：{{item[goodsview[$store.state.app.goodsvivewtype].xhval]}}</li>
									<li> {{goodsview[$store.state.app.goodsvivewtype].zczh}}：{{item[goodsview[$store.state.app.goodsvivewtype].zczhval]}}</li>
									<li> 产品单位：{{item.unit}}</li>
									<li v-if="$store.state.app.token&&$store.state.app.showprice!=1"> 建议零售价：￥{{item.retailprice}}</li>
									<li class="ell "> 生产厂家：{{item.manufacturersname}}</li>
									<li>{{goodsview[$store.state.app.goodsvivewtype].bzgg}}：{{item[goodsview[$store.state.app.goodsvivewtype].bzggval]}}</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- 批号 -->
					<div class="p-0-20 ">
						<div  v-if="item.batchInfoList">
							<div class="flex  absolute b-25 r-2" v-if="!item.batchInfoList.length">
								<div class="border-a h-30 w-70  o-h flex"> 
									<input type="text" class="tc w-50" v-model="item.qty" @blur="onblur(item,1,batchInfoitem)"
										oninput="if(value.toString().indexOf('-')){value = parseInt(value.slice(0, 4))||1
																	}else if(value<0){ value=parseInt(value.slice(1, 4))}">
									<div class="flex-c border-l">
										<img @click.stop="reduce(item,batchInfoitem,1)" class="w-15 h-15 border-b"
											src="../../assets/img/addnum+.png" alt="">
										<img @click.stop="reduce(item,batchInfoitem,0)" src="../../assets/img/addnum-.png"
											alt="">
									</div>
								</div>
							</div>
						</div>
														
															
						<div class="m-t-15 m-b-10 relative">
							<p class="border-b" v-if="item.batchInfoList"></p>
							<p class="bg-f h-20 c-9 absolute r-0 t--8 p-l-10" v-if="item.littleqty!=0&&item.littleqty!=1">注：最小添加数量单位为{{item.littleqty||0}}</p>
						</div>
						
						<div  v-if="item.batchInfoList">
							<ol class="p-t-5 m-t-10" v-if="item.batchInfoList.length">

								<li class="flex h-30 l-h-30 m-b-8" v-for="(batchInfoitem,bkey) in item.batchInfoList">
									<i class="radio-m m-t-8 w-16" :class="{'radio':batchInfoitem.ckeck}" @click.stop="ckeckbatch(gkey,bkey,item.batchInfoList)" v-if="(item.isvisible!=0)&&item.batchInfoList"> </i>
									<div class="flex w90">
										<p v-if="batchInfoitem.batchno=='defaultbno'" class="w-80"><span class=" --b-a --color tc c-ff8 l-h-16 p-0-5 i-b h-20 f-12">默认分配</span> </p>
										<div v-if="batchInfoitem.batchno!='defaultbno'" class="">
											<!-- <span class=" border-a-ff4-1 tc c-ff4 h-20 l-h-16 p-0-5 f-12">促销</span> -->
											<span class="w-160" >批号：{{batchInfoitem.batchno}}</span>
										</div>
										<p class="l-h-35 m-r-10 w-190">业务组：{{batchInfoitem.groupname}}</p>
										
										<p class="w-160" v-if="batchInfoitem.batchno!='defaultbno'">生产日期：{{batchInfoitem.producedate}}</p>
										<p class="w-160" v-if="batchInfoitem.batchno!='defaultbno'">有效日期：{{batchInfoitem.validdate}}</p>
										<p class="w-160" v-if="$store.state.app.token&&$store.state.app.showprice!=1">批发价：<span class="c-f00">￥<span class="bold f-20">{{batchInfoitem.price}}</span></span> </p>
									</div>
									<div class="flex">
										<div class="h-30 ">
											<el-input-number :min="item.fltp?item.fltp:1" :max="item.totalstock" :step="item.fltp?item.fltp:1" type="text" class="tc w-100"  size="mini" v-model="batchInfoitem.qty" @blur="onblur(item,1,batchInfoitem)" />
	
										</div>

									</div>
								</li>
							</ol>
						</div>	
					</div>
					
	            </div>
				
				<div class=" bg-f tc min-h-290 relative" v-if="!inquireArr.length">
					<div class="w-160 jz h-180">
						<img class="w-160 " src="../../assets/img/sc-1.png" >
						<p class="c-9 tc">暂无数据</p>
					</div>
				</div>
	        </li>
	    </ul>
	
	    <template v-if="inquireArr.length">
			<div class="pagination tc">
				<div id="Pagination">
					<el-pagination background layout="prev, pager, next ,total,jumper" :page-size="pagesize"
						@current-change="memberBackhandleCurrentChange"
						:current-page="pageno" :total="total">
					</el-pagination>
				</div>
			</div>
	    </template>
		
		
		<!--没有库存 -->
		<div class="Mycollection">
		    <el-dialog title="提示" :visible.sync="Mycollectiondialog">
		        <div class="h-400">
		            <ul class="flex wraps ">
		                <li class="m-b-10" v-for="(item,index) in moreitem">
							
							<div v-if="item.batchno=='defaultbno'">
							{{index+1}}	：您购买的【{{item.goodsname}}】、批号【默认批号】<span v-if="showStock!=0">、目前库存为 {{item.stock}}</span>，商品下次到货时间预计为{{item.arrivalday}}
							</div>
							<div v-else class="c-ff3">
							{{index+1}}	：您购买的【{{item.goodsname}}】、批号【{{item.batchno}}】<span v-if="showStock!=0">、目前库存为 {{item.stock}}</span>，请修改采购数量
							</div>
						</li>
						 <!-- <li class="m-b-10" v-for="(item,index) in moreitem">您购买的【{{item.goodsname}} 】、批号【{{item.batchno}}】、目前库存为{{item.stock}}，请修改采购数量</li> -->
		            </ul>
		        </div>
		        <div class="flex s-c f-14  tc absolute b-50  w100 l-0 p-l-120 p-r-120">
		            <p class="w-100 rad-2 h-34 l-h-32 c-b14e cursor border-a-00b-2 m-0-30" @click="addcardmone(0)">取消</p>
		            <p class="w-100 rad-2 h-34 l-h-34 bg-00B c-f cursor m-0-30" @click="addcardmone(1)" v-if="quxiaoaddcardmone">确定</p>
		        </div>
		    </el-dialog>
		</div>
	
	    <div style="width: 1020px; display: flex;" class="relative " :class="{'fixed':isfixed}" v-if="inquireArr.length">
	        <div class="flex  bg-f pwidth">
	           
				<div class="p-l-20 p-t-10 flex l-h-30"  @click="allcheck"> <i class="radio-m m-r-10 m-t-8" :class="{'radio':manage.allactive}"></i>全选</div>
				<div class="tibtn flex">
					<p class="num m-r-30">已选商品 <span class="c-ffa f-20 m-0-10">{{selecttotal}}</span> 件</p>
					<p class="cursor --bg" @click="dialogTableVisiblefn">{{$store.state.app.Operational.goodlist.addcart}}</p>
					
				</div>
	        </div>
	    </div>
	</div>
</template>

<script>
	var showStock = sessionStorage.getItem('showStock');
	export default {
		data() {
			return {
				menuitemAactive: '',
				manage: {
					multipleSelection: [],
					newSubuser: false,
					allactive: false,
					//全选标志
					value: 1,
					getuser: []
				},
				total: 0,
				pageno: 1,
				pagesize: 10,
				inquireArr: [],
				//收藏列表
				inquireArrfz: [],
				//收藏列表
				yxgoods: [],
				//有效商品
				isfixed: false,
				//是否固定结算
				selecttotal: 0,
				//选择的总数量
				searchvalue: '',
				//搜索内容
				Mycollectiondialog: false,
				moreitem: [],
				//弹窗提示
				quxiaoaddcardmone: true,
				//添加购车显示确定按钮
				showStock: showStock,
				pricetype: {'1': '折扣' ,'2' :'立减价格' ,'3' :'秒杀',  '5':'买赠'},
				checkgoods:[]
			};
		},
		
		created: function created() { 
			 this.inquire()
		},
		methods: {
			reduce: function reduce(item, batchInfoitem, index) {
				// 批号加数量
				if (batchInfoitem) {
					var value;
					if (item.littleqty != 0) {
						value = index ? batchInfoitem.qty * 1 + item.littleqty : batchInfoitem.qty * 1 - item.littleqty;
						value < item.littleqty ? value = item.littleqty : value = value;
					} else {
						value = index ? batchInfoitem.qty * 1 + 1 : batchInfoitem.qty * 1 - 1;
						value < 0 ? value = 0 : value = value;
					}
	
					this.inquireArr.filter(function(list) {
						if (list.id == item.id) {
							for (var i in list.batchInfoList) {
								if (list.batchInfoList[i].batchno == batchInfoitem.batchno) list.batchInfoList[i]['qty'] = value;
							}
						}
					});
				} else {
					// 无批号加数量
					var _value;
		
					if (item.littleqty > 0) {
						_value = index ? item.qty * 1 + item.littleqty : item.qty * 1 - item.littleqty;
						_value < item.littleqty ? _value = item.littleqty : _value = _value;
					} else {
						_value = index ? item.qty * 1 + 1 : item.qty * 1 - 1;
						_value < 0 ? _value = 0 : _value = _value;
					}
		
					this.inquireArr.filter(function(list) {
						if (list.id == item.id) {
							list['qty'] = _value;
						}
					});
				}
			},
			
			// 限制最小采购值
			onblur: function onblur(item, typs, batchInfoitem) {
				if (typs) {
					// 有批号
					this.inquireArr.filter(function(list) {
						if (list.id == item.id) {
							for (var i in list.batchInfoList) {
								if (list.batchInfoList[i].batchno == batchInfoitem.batchno) {
									if (item.littleqty != 0) {
										if (parseInt(batchInfoitem['qty']) <= item.littleqty) {
											console.log('batchInfoitem小于', parseInt(batchInfoitem['qty']));
											list.batchInfoList[i]['qty'] = item.littleqty;
										} else {
											console.log('batchInfoitem大于', parseInt(batchInfoitem['qty']));
											list.batchInfoList[i]['qty'] = Math.ceil(parseInt(batchInfoitem['qty']) / item.littleqty) * item.littleqty;
										}
									} else {}
								}
							}
						}
					});
				} else {
					// 无批号
					this.inquireArr.filter(function(list) {
						if (list.id == item.id) {
							if (parseInt(item['qty']) <= item.littleqty) {
								console.log('小于', parseInt(item['qty']));
								list['qty'] = item.littleqty;
							} else {
								console.log('大于', parseInt(item['qty']));
								list['qty'] = Math.ceil(parseInt(item['qty']) / item.littleqty) * item.littleqty;
							}
						}
					});
				}
		
				console.log(this.inquireArr);
			},
			
			// 选择商品批号
			
			ckeckbatch(gkey,bkey,batchInfoitem) {
				var inquireArr = this.inquireArr
				var num=0
					// selecttotal = this.selecttotal;
			
				inquireArr[gkey].batchInfoList[bkey].ckeck=!inquireArr[gkey].batchInfoList[bkey].ckeck
				
				inquireArr[gkey].batchInfoList.map(res=>{
					if (res.ckeck) num++
				})
				num==inquireArr[gkey].batchInfoList.length?inquireArr[gkey].ckeck=true:inquireArr[gkey].ckeck=false

				this.selecttotalnumber();
				this.inquireArr = inquireArr;
			},
			
			// 选择商品
			ckeckgoods: function ckeckgoods(item, batchInfoitem) {
				var inquireArr = this.inquireArr;
				console.log(batchInfoitem);
				// if(!batchInfoitem)return this.$notify({title: '提示',message: '该商品没有',type: 'error',duration:2000});
				if (batchInfoitem&&batchInfoitem.length) {
					for (var i in inquireArr) {
						if (inquireArr[i].goodsid == item.goodsid) inquireArr[i].ckeck = !inquireArr[i].ckeck;
		
						for (var k in inquireArr[i].batchInfoList) {
							if (inquireArr[i].goodsid == item.goodsid) {
								inquireArr[i].batchInfoList[k].ckeck = inquireArr[i].ckeck;
							}
						}
					}
		
					this.inquireArr = inquireArr;
				} else {
					for (var _i in inquireArr) {
						console.log(inquireArr[_i].goodsid, item.goodsid);
		
						if (inquireArr[_i].goodsid == item.goodsid) {
							console.log(this.selecttotal);
							inquireArr[_i].ckeck = !inquireArr[_i].ckeck;
						}
					}
		
					this.inquireArr = inquireArr;
				}
		
				this.selecttotalnumber();
			},
			
			// 计算选中的数量
			selecttotalnumber: function selecttotalnumber() {
				var inquireArr = this.inquireArr;
				var selecttotal = 0;
				console.log('inquireArr',inquireArr)
				for (var i in inquireArr) {
					if(inquireArr[i].batchInfoList){
						if (inquireArr[i].batchInfoList.length) {
							for (var k in inquireArr[i].batchInfoList) {
								if (inquireArr[i].batchInfoList[k].ckeck) selecttotal = selecttotal + 1;
							}
						} else {
							if (inquireArr[i].ckeck) selecttotal = selecttotal + 1;
						}
					}else{
						if (inquireArr[i].ckeck) selecttotal = selecttotal + 1;
					}
				}
				console.log('selecttotal',selecttotal)
				this.selecttotal = selecttotal;
			},
			
			// // 全选
			allcheck: function allcheck() {
				this.manage.allactive = !this.manage.allactive;
				if (this.manage.allactive) {
					for (var i in this.inquireArr) {
						this.inquireArr[i].ckeck = true;
						if(this.inquireArr[i].batchInfoList)this.inquireArr[i].batchInfoList.map(e=>{e.ckeck=true})
						
						this.checkgoods.push(this.inquireArr[i]);
						
					} 
		
				} else {
					for (var _i2 in this.inquireArr) {
						this.inquireArr[_i2].ckeck = false;
						if(this.inquireArr[_i2].batchInfoList)this.inquireArr[_i2].batchInfoList.map(e=>{e.ckeck=false})
					}
		
					this.checkgoods = [];
				}
				this.selecttotalnumber()
			},
			
			// 加入收藏/取消收藏
			addCollection: function addCollection(goodsid, type) {
				this.addCollections(goodsid, type).then(res=>{
					// if (this.goodsinfodata.id == goodsid) {
					// 	this.goodsinfodata.isfav = this.goodsinfodata.isfav ? 0 : 1;
					// }
					this.inquire();
				})
			},
			
			// 跳转详情
			gotodetail: function gotodetail(id) {
				this.$router.push({path:'/detail',query:{goodsid:id,key:2}})
			
			},
			
			
			// 批量加入购w物车
			dialogTableVisiblefn: function dialogTableVisiblefn() {
				var inquireArr = this.inquireArr,
					selecttotal = this.selecttotal;
					console.log('selecttotal',selecttotal);
				if (selecttotal == 0) {
					this.$notify({title: '提示',message: '请选择商品',type: 'error',duration:2000});
					return;
				}
		
				this.quxiaoaddcardmone = true;
				var items = [];
				console.log('inquireArr',inquireArr);
		
				for (var i in inquireArr) {
					if (inquireArr[i].batchInfoList&&inquireArr[i].batchInfoList.length) {
						for (var k in inquireArr[i].batchInfoList) {
							if (inquireArr[i].batchInfoList[k].ckeck) {
								// window.addcar_l(inquireArr[i], inquireArr[i].batchInfoList[k].batchno, inquireArr[i].batchInfoList[k].qty)
								
								var item = {
									batchno: inquireArr[i].batchInfoList[k].batchno,
									stock: inquireArr[i].batchInfoList[k].stock,
									goodsid: inquireArr[i].id,
									goodsno: inquireArr[i].goodsno,
									goodsname: inquireArr[i].goodsname,
									arrivalday: inquireArr[i].arrivalday,
									qty: inquireArr[i].batchInfoList[k].qty,
									batchnosalegroupname: inquireArr[i].batchInfoList[k].groupname,
									batchnosalegroupno: inquireArr[i].batchInfoList[k].groupno,
								};
								items.push(item);
							}
						}
					} else {
						if (inquireArr[i].ckeck) {
							var item = {
								batchno: '',
								goodsid: inquireArr[i].id,
								goodsno: inquireArr[i].goodsno,
								qty: inquireArr[i].qty,
								arrivalday: inquireArr[i].arrivalday,
								batchnosalegroupname:inquireArr[i].groupname,
								batchnosalegroupno:inquireArr[i].groupno,
							};
							items.push(item);
						}
					}
				}
		
				var moreitem = [];
		
				for (var _k in items) {
					if (items[_k].batchno) {
						if(items[_k].qty==0){
							this.$notify({title: '提示',message: '加入数量不能为0',type: 'error',duration:1000});
							return
						}
						if (items[_k].qty > items[_k].stock) {
							moreitem.push(items[_k]);
							if (items[_k].batchno != 'defaultbno') this.quxiaoaddcardmone = false;
						}
					}
				}
		
				console.log('items',items);
				this.items = items;
				this.moreitem = moreitem;
				if (moreitem.length) this.Mycollectiondialog = true;
				if (!moreitem.length) this.addcardmone(1);
			},
			
			
			 
			// 批量加入购车
			addcardmone(){
				var data = {items: this.items,};
				this.requset('/b2bshoppingcart/addbatchitem', data, {met:'json'} ).then((res)=> {
					console.log(res)
					if (res.status == "success") {
						this.cartNumber();
						this.$notify({title: '提示',message: '加入成功',type: 'success',duration:1000});
					}else{
						this.$notify({title: '提示',message: res.message,type: 'error',duration:2000});
					}
				})
				// .catch(res=>{
				// 	console.log(res)
				// 	this.$notify({title: '提示',message: '加入失败',type: 'error',duration:2000});
				// });	
				
				this.Mycollectiondialog = false;
			},
			
			
			search: function search() {
				this.inquire();
			},
			
			
			// 获取收藏列表POST /goodsfavorites/list
			inquire: function inquire() {
				var self = this;
				var url = '/goodsfavorites/list';
				var data = {
					pageno: this.pageno,
					pagesize: this.pagesize,
					query: this.searchvalue,
				};

				this.requset(url, data).then((data)=>{
					this.authorization(data)
					if (data.status == 'success') {
						data.data.rows.filter(function(item) {
							for (var i in item.batchInfoList) {
								item.batchInfoList[i]['qty'] = item.fltp?item.fltp:item.littleqty ? item.littleqty : 0;
								item.batchInfoList[i].ckeck = false;
							}
							item['qty'] =item.fltp?item.fltp:item.littleqty ? item.littleqty : 0;
							item['ckeck'] = false;
						});
						self.inquireArr = data.data.rows;
						self.inquireArrfz = data.data.rows;
						self.total = data.data.total;
					}	
				});
			},
			
			
			memberBackhandleSizeChange: function memberBackhandleSizeChange() {},
			memberBackhandleCurrentChange: function memberBackhandleCurrentChange(tab, event) {
				this.pageno = tab;
				this.selecttotal = 0;
				this.inquire(tab);
			},
			
			// 获取滚动距离
			menu: function menu() {
				// offsetHeight
				if (this.menuitemAactive != '1-4') return;
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop; // 获取元素高度
		
				var height = this.$refs.element.offsetHeight - 550; // 是否固定结算条
				if (this.scroll > height) {
					this.isfixed = false;
				} else {
					this.isfixed = true;
				}
			}
		},
	}
</script>

<style>
</style>
