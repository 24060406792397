<template>
	<!-- 物流信息 -->
	<div class="memberSetmeal memberorderinfo Logistics">
	    <div class="detail">
	        <div class="location p-20  f-16 bg-f  flex relative">
	            <el-breadcrumb separator-class="el-icon-arrow-right">
	                <el-breadcrumb-item> 你所在的位置：<i class="el-icon-s-home"></i>
	                    <span class="cursor" @click="$parent.orderinfoactive=1">个人中心</span>
	                </el-breadcrumb-item>
	                <el-breadcrumb-item>物流信息</el-breadcrumb-item>
	                <el-breadcrumb-item v-if="detail.tradeid">{{detail.tradeid}}</el-breadcrumb-item>
	
	            </el-breadcrumb>
	            <span class="cursor c-00b fanhui" @click="$parent.orderinfoactive=1">返回</span>
	        </div>
	
	
	        <div class="Establish bg-f m-t-10 p-0-20 p-b-30" style="min-height: 487px;">
	
	            <div class="p-10 border-b">
	                <!-- 物流单号：{{detail.logisticsno}} -->
	               <template>
	                    <el-select v-model="logisticcode" placeholder="全部" @change="elselectYY" style="width: 260px;">
	                        <el-option
	                            v-for="item in options"
	                            :key="item.value"
	                            :label="(item.shippername||'')+'——'+item.logisticcode"
	                            :value="item.logisticcode">
	                        </el-option>
	                    </el-select>
	                </template>
	            </div>
	
	            <div v-show="!ifwl">
	                <div class="w100 h-180 p-60-130" v-show="traces.length">
	                    <el-steps :active="wlactive">
	                        <el-step title="揽件" icon="el-icon-upload"></el-step>
	                        <el-step title="运输">
	                            <i v-show="wlactive>=2" class="el-icon2" slot="icon"></i>
	                        </el-step>
	                        <el-step title="派送">
	                            <i v-show="wlactive>=3" class="el-icon3" slot="icon"></i>
	                        </el-step>
	                        <el-step title="取件">
	                            <i v-show="wlactive==5" class="el-icon4" slot="icon"></i>
	                        </el-step>
	                    </el-steps>
	                </div>
	                <div class="stepscent m-t-20 ">
	                    <p class="m-b-20 p2 relative" v-for="(item,index) in logisticdetail.traces">
	                        <i class="relative"><img v-show="index==0"
	                                :src="logisticdetail.state==4?require('../../assets/img/lcerr.png'):require('../../assets/img/lcjh.png')"
	                                alt="">
	                            <img v-show="index!=0" src="../../assets/img/lcmr.png" alt="">
	  
	                            <i class=" vertical" v-show="index!=0"></i>
	                        </i>
	                        <span class="wldetailcentent"
	                            :class="{'c-ff3':logisticdetail.state==4&&index==0}">{{item.acceptTime}}
	                            {{item.acceptStation}}</span>
	
	                    </p>
	                </div>
	            </div>
	
	            <div v-show="ifwl" class="p-20">
	                <img style="width: 500px;height: 300px;margin: auto;display: block;" src="../../assets/img/wuya.gif" alt="">
	            </div>
	
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
					logisticcode: '',
					shippername:"",
					options: [], 
					logisticdetail: [], 
					traces: [], 
					ifwl: false, 
					wlactive: 1, 
			}
		},
		props:['detail'],
		created() {
				document.documentElement.scrollTop = 0;
			this.ordertraces()
			this.ordertracesdetail()
		},
		methods:{
			
			// 快递单号列表 POST 开发完成 /trade/logistilog
			ordertraces(){
				var Url = '/trade/logistilog';
				var data = {tradeid: this.detail.tradeid};
				this.requset(Url, data).then(data=>{
						this.options = data.data;
						if (data.data.length) {
							this.logisticcode = data.data[0].logisticcode;
							this.shippername = data.data[0].shippername;
							// if(this.logisticcode) this.ordertracesdetail();
							if(this.logisticcode!='自配'&&this.shippername!='自配')this.ordertracesdetail()
						} else {
							this.ifwl = true;
						}
					}).catch(res=>{
						console.log(res)
						// this.$notify({title: '提示',message: res.message,type: 'error',duration:2000});
					})
			},
			
			// 查快递详情 POST 开发完成 /tradefront/ordertraces
			ordertracesdetail: function ordertracesdetail() {
				var _this5 = this;
				var Url = '/tradefront/ordertraces';
				var self = this;
				var data = {
					shipperCode: '',
					logisticCode: this.detail.logisticsno, //this.logisticcode,
					// openid: openid,
					unionid: 'b2b' //先写b2b 可能要改
				};
				
				
				this.requset(Url, data).then(function(data) {
						if (data.data != null) {
							console.log(data.data.traces.length);
							_this5.traces = data.data.traces;
							_this5.wlactive = parseInt(data.data.state);
							if (data.data.state == 3) _this5.wlactive = 5;
							if (data.data.state == 5) _this5.wlactive = 3;
							_this5.logisticdetail = data.data;
					
							if (!data.data.traces.length) {
								_this5.ifwl = true;
							} else {
								_this5.ifwl = false;
							}
						} else {
							_this5.ifwl = true;
							_this5.logisticdetail = [];
							_this5.traces = [];
						}
					}, function(err) {
						_this5.ifwl = true; // Collection(err.message)
					});
			},
			
			
			// 选择快递单号
			elselectYY: function elselectYY(e) {
			
				var {logisticdetail}=this,shippername=''
				this.logisticcode = e;
				logisticdetail.map(res=>{if(res.logisticcode==e)shippername=res.shippername})
				if(e!='自配'&&shippername!='自配')this.ordertracesdetail()
			},
			
			
		}
	}
	
	
</script>

<style>
</style>
