<template>
	
	<!-- 订单详情 -->
	<div class="memberSetmeal memberorderinfo">

	    <!-- 查看详情 -->
	    <div class="detail">
	        <div class="location  p-10 f-16 bg-f  flex relative">
				
	            <div class="p-10">
					<el-breadcrumb separator-class="el-icon-arrow-right">
					    <el-breadcrumb-item> 你所在的位置：<i class="el-icon-s-home"></i>
					        <span class="cursor" @click="$emit('ret',1)" >个人中心</span>
					    </el-breadcrumb-item>
					    <el-breadcrumb-item>订单详情</el-breadcrumb-item>
					    <el-breadcrumb-item v-if="tradeinfo">{{tradeinfo.tradeid}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				
	            <span class="cursor c-f el-button--primary p-10-20 rad-4 f-12 m-r-10" @click="$emit('ret',1)">返回</span>
	        </div>
	
	
	        <!-- 收货信息： -->
	        <div class=" bg-f m-t-10" v-if="tradeinfo">
	            <h3 class="bg-f p-l-20 title bold"><i class="shu-2 m-r-5"></i> 收货信息</h3>
	            <div class="p-l-20 p-r-20 p-b-10">
	                <div class="border-a-e  p-l-10 p-r-20 p-b-10 p-t-20 relative m-t-10">
	                    <h3 class="absolute t--12 p-0-5 bg-f l-5 f-14 bold">收货公司：{{tradeinfo.companyname}}</h3>
	                    <ul>
	                        <li class="flex start m-b-10 cursor">
	                            <div class=" bg-f5 w-100 h-30 l-h-30 m-r-40 tc relative">
	                                {{tradeinfo.recuser}}
	                            </div>
	                            <div class="l-h-30 flex  w100">
	                                <p>
	                                    <span class="m-r-10">{{tradeinfo.recphone}}</span>
	                                    <span class="m-r-10">{{tradeinfo.recaddress}}</span>
	                                </p>
	
	                            </div>
	                        </li>
	                    </ul>
	                </div>
	            </div>
				
				<div v-if="$store.state.app.showprice!=1">
					<h3 class="bg-f p-l-20 title bold"><i class="shu-2 m-r-5"></i>{{$store.state.app.Operational.member.Paymentinformation}}</h3>
					<ul class="flex p-l-20 p-r-20 p-b-20 start" v-if="$store.state.app.Operational">
						<li class="m-r-50">{{$store.state.app.Operational.member.paytype}}：{{paytype[tradeinfo.paytypeid]}}</li>
						<li class="m-r-50">{{$store.state.app.Operational.member.paytime}}：{{tradeinfo.paydate||$store.state.app.Operational.member.Unpaid}}</li>
						<li class="m-r-50">{{$store.state.app.Operational.member.Paymentamount}}：￥{{tradeinfo.payvalue}}</li>
						<li class="m-r-50" v-if="$store.state.app.login.cardid!=tradeinfo.cardid">下单人：{{tradeinfo.cardname}}</li>
						<!-- <a :href="downloadurl"  class="c-b14e " v-if="tradeinfo.flag==3||tradeinfo.flag==4">下载批检报告</a > -->
						<a :href="downloadurl" class="c-b14e " v-if="tradeinfo.existsBatchReport">下载批检报告</a>
						<li class="m-r-50">优惠金额：￥{{tradeinfo.discountvalue}}</li>
					</ul>
				</div>
	        </div>
	
	
	       
		   <!-- 退货信息： -->
		   <div class=" bg-f m-t-10" v-if="active==1">
		       <h3 class="bg-f p-l-20 title bold"><i class="shu-2 m-r-5"></i> {{$store.state.app.Operational.member.Paymentinformation}}</h3>
		       <ul class="flex p-l-20 p-r-20 p-b-20 start" v-if="tradeinfo&&$store.state.app.Operational">
		           <li class="m-r-100">{{$store.state.app.Operational.member.paytype}}：{{paytype[tradeinfo.paytypeid]}}</li>
		           <li class="m-r-100">{{$store.state.app.Operational.member.paytime}}：{{tradeinfo.paydate}}</li>
		           <li class="m-r-100">{{$store.state.app.Operational.member.Paymentamount}}：￥{{tradeinfo.payvalue}}</li>
				  
		       </ul>
		   
		       <div class="" v-if="active==1">
		           <h3 class="bg-f p-l-20 title bold"><i class="shu-2 m-r-5"></i> 审核信息</h3>
		           <ul class="flex p-l-20 p-r-20 p-b-20 start">
		               <li class="m-r-100" v-if="retinfo">审核状态：{{shenhe[retinfo.flag]}}</li>
		               <li class="m-r-100" v-if="retinfo">审核时间：{{retinfo.examinetime}}</li>
		               <li class="m-r-100" v-if="retinfo">审核意见：{{retinfo.rejectionreason}}</li>
		           </ul>
		       </div>
		   </div>
		   
		   <!-- 退款信息： -->
		   <div class=" bg-f m-t-10" v-if="active==2||active==3">
		       <h3 class="bg-f p-l-20 title bold"><i class="shu-2 m-r-5"></i> 退款信息</h3>
		       <ul class="flex p-l-20 p-r-20 p-b-20 start" v-if="tradeinfo">
		           <li class="m-r-100">退款方式：{{paytype[tradeinfo.paytypeid]}}</li>
		           <li class="m-r-100">退款时间：{{retinfo.examinetime||'未退款'}}</li>
		           <li class="m-r-100">退款金额：￥{{retinfo.payvalue}}</li>
		       </ul>
		   </div>
		   
		   
		   
			<div class="table m-t-10   ">
	            <div v-for="item in Suborder" class="m-b-10 bg-f p-20">
					<!-- v-if="isb2bparent" -->
					<div class="m-b-10 relative" > 
						<div class="bold f-16 m-b-15"> 订单编号：{{item.tradeid}} </div> 
						<div class="flex start ">
							<span class="w20">业务组：{{item.salegroupname}}</span>
							<span class="w20" v-if="$store.state.app.showprice!=1">支付金额：{{item.payvalue}}</span> 
							<span class="w20" v-if="$store.state.app.showprice!=1">优惠金额：{{item.discountvalue}}</span>
						</div>
						<i v-if="item.erpbillstatus==100" class="iconfont b2b-yiqueren --color f-60 absolute r-10 t--10"></i>
					</div>
				
	                <el-table :data="item.tradegoodsList" border stripe style="width: 100%">
	                  
						<el-table-column prop="goodsname" :label="goodsview[$store.state.app.goodsvivewtype].tymc" width="250">
	                        <template slot-scope="scope">
	                            <span class="ell">{{scope.row[goodsview[$store.state.app.goodsvivewtype].tymcval1]}}</span>
	                            <img class="w-20 h-20" v-show="scope.row.isgift=='1'" src="../../assets/img/zp.png" alt="" />
	                        </template>
	                    </el-table-column>
						<el-table-column prop="approvalno" :label="goodsview[$store.state.app.goodsvivewtype].gg" width="150">
							<template slot-scope="scope">{{scope.row[goodsview[$store.state.app.goodsvivewtype].ggval]}}</template>
						</el-table-column>
						<el-table-column prop="" label="单位" width="150"></el-table-column>
						<el-table-column prop="approvalno" :label="goodsview[$store.state.app.goodsvivewtype].xh" width="150">
							<template slot-scope="scope">{{scope.row[goodsview[$store.state.app.goodsvivewtype].xhval]}}</template>
						</el-table-column>
	                    <el-table-column prop="manufacturersname" label="生产厂家" width="200"></el-table-column>
	                    <el-table-column prop="qty"  :label="$store.state.app.Operational.member.baybumber" width="130"></el-table-column>
	                    <el-table-column prop="goodsid" label="商品编号" width="150"></el-table-column>
	                    <el-table-column prop="price" label="批发价" width="130" v-if="$store.state.app.showprice!=1"></el-table-column>
	                    <el-table-column prop="discount" label="折扣金额" show-overflow-tooltip width="100" v-if="$store.state.app.showprice!=1"> </el-table-column>
	                    <el-table-column prop="dealprice" :label="$store.state.app.Operational.member.Paymentamount" show-overflow-tooltip width="100" v-if="$store.state.app.showprice!=1"> </el-table-column>
						
	                    <el-table-column prop="batchno" label="批号" width="130"></el-table-column>
	                    <el-table-column prop="approvalno" :label="goodsview[$store.state.app.goodsvivewtype].zczh" width="150">
							<template slot-scope="scope">{{scope.row[goodsview[$store.state.app.goodsvivewtype].zczhval]}}</template>
						</el-table-column>
	                    <el-table-column prop="spec" :label="goodsview[$store.state.app.goodsvivewtype].gg" width="130">
							<template slot-scope="scope">{{scope.row[goodsview[$store.state.app.goodsvivewtype].ggval]}}</template>
						</el-table-column>
	                    <el-table-column prop="promotesales.salesname" label="促销名称" width="400" class="c-ff3">
	                        <template slot-scope="scope">
	                            <span v-if="scope.row.promotesales" class="c-ff3"><span v-show="scope.row.isgift=='1'">【赠品】</span>{{scope.row.promotesales.salesname}}</span>
	                        </template>
	                    </el-table-column>
	                    <!-- <el-table-column v-if="rowinfo.combotype!='数量'" prop="comboamount" label="套餐金额"></el-table-column>
	                    <el-table-column v-if="rowinfo.combotype!='数量'" prop="remianamount" label="剩余金额"></el-table-column> -->
	                </el-table>
	            </div>

				<template>
					<div class="bg-f p-10 tc " >
						<div id="Pagination">
							<el-pagination background layout="prev, pager, next ,total,jumper" 
								@current-change="memberBackdetal"
								:current-page="orderPage" :total="tableDatadetailtotal">
							</el-pagination>
						</div>
					</div>
				</template>
	        </div>
	
	    </div>
	</div>
</template>

<script>
		var companyname = sessionStorage.getItem('companyname');
		var openid= sessionStorage.getItem('openid');
		var companycode = sessionStorage.getItem('companycode');

	export default{
		data(){
			companyname = sessionStorage.getItem('companyname');
			openid= sessionStorage.getItem('openid');
			companycode = sessionStorage.getItem('companycode');
			return{
				companyname:companyname,
				companycode:companycode,
				openid:openid,
				tradeinfo: null, 
				tableDatadetail: [],					//详情商品数据
				tableDatadetailtotal: 0,
				orderPage: 1,
				pagesize:10,
				orderDotal: 10, 
				paytype: ['货到付款', '微信支付', '储值卡', '支付宝', '银行转账', '信用付', "现金支付",'其它支付', "线下支付",'医保混合支付'],
				retinfo: null,				//退货详情的收货地址信息
				Suborder:[],
				isb2bparent:0
			}
		},
		props:['detail','active'],
		created() {
			this.getlookinfo()
		},
		
		
		
		methods:{
			
			// 查看订单详情
			getlookinfo: function getlookinfo(type) {
				console.log('查看详情1',this.detail);
				this.isreturnprv = type;
				var self = this;
				var Url = '/trade/info';
				var data = {
					tradeid: this.detail.tradeid,
					openid: openid,
					pageno: this.orderPage,
					pagesize: this.pagesize,
					checkBatch:1,
				};

				this.requset(Url, data).then((data)=> {
					if (data.status == 'success') {
						document.documentElement.scrollTop = 0;
						var result = data.data.rows;
						var tradeinfo= data.data.tradeinfo
						tradeinfo['tradegoodsList']=result
						for (var i in result) {
							result[i].batchno = result[i].batchno != 'defaultbno' ? result[i].batchno : '默认分配';
						}
						if(tradeinfo.isb2bparent){
							this.isb2bparent=tradeinfo.isb2bparent
							this.Suborder=[tradeinfo]
							this.getSuborder(tradeinfo.tradeid)
						}else{
							
							this.Suborder=[tradeinfo]
							
							console.log('Suborder',this.Suborder)
							this.Suborder[0]['tradegoodsList']=result
						}
						self.tableDatadetail = result;
						self.tableDatadetailtotal = data.data.total;
						self.tradeinfo = data.data.tradeinfo;

						self.download();
					}
				});
				
			},
			
			// 查询子单
			getSuborder(tradeinfo){
				
				this.requset('/trade/selectByB2bParenttradeid', {parenttradeid:tradeinfo}).then((data)=>{
					if(data.data.length)this.Suborder=data.data
				})
				
				
			},
			

			// 详情商品分页
			memberBackdetal(tab, event) {
				this.orderPage = tab;
				this.getlookinfo();
			},
			
			// 下载批检报告
			download: function download() {
				this.downloadurl =this.downloadExcel('/trade/batchgoodsdata', this.detail.tradeid);
			},
			

			
			
			
		}
		
		
	}
	
</script>

<style>
	.el-table__empty-text{text-align: left;}
	/* ::-webkit-scrollbar{display: block;} */
	.el-table th{background: rgba(2,101,255,0.1);}
	.el-table td, .el-table th {padding: 0;line-height: 32px;}
	.memberorderinfo .el-table__row{height: 32px;}
	::-webkit-scrollbar{height: 8px;display: block !important;}
	.el-table--border{border-bottom: 1px solid #ebeef5 !important;}

	.el-table--striped .el-table__body tr.el-table__row--striped td {background:#F5F7FA}
</style>
