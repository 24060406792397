<template>
	<!-- 订单管理 -->
	<div>
		<div class="memberProposal memberBack">
			<h3 class="bg-f p-l-20 title m-b-10 f-16 bold" v-if="alldetail == 1">
				<i class="shu m-r-5 "></i>
				订单管理
			</h3>
				
			<div class=" " v-if="alldetail == 1">
				<ul class=" h-40  bg-f m-b-10 navaste">
					<li @click="btnactive(index)" class="cursor c-6 border-r" :class="{ 'c-00b blod ': active == index }" v-for="(item, index) in nav">{{ item }}</li>
				</ul>
				<div class="navtives">
					<!-- 全部订单 -->
					<div v-show="active == 0" class="p-0-10 bg-f p-b-5" style="height: 100%;">
						<div  v-if="total!=0">
							<div class="table" v-if="$store.state.app.Operational">
								<el-table :data="allorderlist.rows" stripe style="width: 100%" >
									<el-table-column prop="tradeid" :label="$store.state.app.Operational.member.Ordernumber" width="200">
										<template slot-scope="scope">
											<span>{{ scope.row.tradeid }}</span>
											<img v-show="scope.row.flag == '退货中'" src="../../assets/img/ret.png" alt="" />
										</template>
									</el-table-column>
									<el-table-column prop="paytype" width="120" :label="$store.state.app.Operational.member.paytype"></el-table-column>
									<el-table-column prop="sumqty" :label="$store.state.app.Operational.member.baybumber" width="120"></el-table-column>
									<el-table-column width="120" :label="$store.state.app.Operational.member.Orderstatus">
										<template slot-scope="scope">
											<div>{{ flegs[scope.row.flag] }}</div>
										</template>
									</el-table-column>

									<el-table-column prop="tradedate" :label="$store.state.app.Operational.member.Ordertime"></el-table-column>
									<el-table-column label="操作" width="220">
										<template slot-scope="scope">
											<div class="flex start">
												<p class="c-ffa cursor" @click="alllistdetail(scope.row, 'look')">查看详情</p>
												<span class="m-0-5 c-9" v-show="scope.row.retqty != scope.row.sumqty && scope.row.flag == 4">|</span>
												<p
													class="c-ff3 cursor"
													v-show="scope.row.retqty != scope.row.sumqty && scope.row.flag == 4"
													@click.stop="alllistdetail(scope.row, 'shen')"
												>
													申请退货
												</p>
												<span class="m-0-5 c-9" v-show="scope.row.flag == 3">|</span>
												<p class="c-b14e cursor" v-show="scope.row.flag == 3" @click.stop="wcOrder(scope.row.tradeid)">
													<!-- 订单完成 -->
													{{ $store.state.app.Operational.member.Ordercomplete }}
												</p>

												<span class="m-0-5 c-9" v-show="scope.row.flag == 1">|</span>
												<p class="c-b14e cursor" v-show="scope.row.flag == 1" @click.stop="showpayment(scope.row.tradeid)">
													<!-- 立即支付 -->
													{{ $store.state.app.Operational.member.goTopaid }}
												</p>
												<span class="m-0-5 c-9" v-show="scope.row.invoiceflag == 100"   >|</span>
												<p v-show="scope.row.invoiceflag == 100" class="c-00b cursor" @click.stop="seeinvoice(scope.row)">查看发票</p>
											</div>
										</template>
									</el-table-column>
								</el-table>
							</div>

							<template>
								<div class="pagination tc">
									<div id="Pagination">
										<el-pagination
											background
											layout="prev, pager, next ,total,jumper"
											:page-size="pagesize"
											@current-change="memberBackhandleCurrentChange"
											:current-page="currentPage1"
											:total="allorderlist.total"
										></el-pagination>
									</div>
								</div>
							</template>
						</div>
						<div v-if="total==0" class="tc m-b-75">
							<img class="w-200 h-150 m-t-100 c-9" src="../../assets/img/sc-1.png" >
							<p>暂无数据</p>
						</div>
						
					</div>

					<!-- 退货订单列表 -->
					<div v-show="active != 0" class="bg-f p-0-10  p-b-5">
						<div  v-if="memberBacktableDatatotal!=0">
							<div class="table">
								<el-table :data="memberBacktableData" stripe style="width: 100%">
									<el-table-column prop="retid" label="退货申请单号" width="210"></el-table-column>
									<el-table-column prop="tradeid" :label="$store.state.app.Operational.member.Ordernumber" width="160"></el-table-column>
									<el-table-column prop="buyqty" width="80" :label="$store.state.app.Operational.member.baybumber"></el-table-column>
									<el-table-column prop="retqty" width="80" label="退货数量"></el-table-column>
									<el-table-column prop="flag" :label="$store.state.app.Operational.member.Orderstatus" width="120"></el-table-column>
									<el-table-column prop="tradedate" :label="$store.state.app.Operational.member.Ordertime"></el-table-column>
									<el-table-column prop="retdate" label="申请退货时间"></el-table-column>
									<el-table-column label="操作" width="80">
										<template slot-scope="scope">
											<div class="">
												<p class="c-ffa cursor" @click="retdetailgoods(scope.row)">查看详情</p>
												<p class="c-b14e cursor" v-show="scope.row.flag == '退货申请成功'" @click.stop="retgoodssuccess(scope.row.retid)">发货</p>
											</div>
										</template>
									</el-table-column>
								</el-table>
							</div>

							<template>
								<div class="pagination tc">
									<div id="Pagination">
										<el-pagination
											background
											layout="prev, pager, next ,total,jumper"
											:page-size="pagesize"
											@current-change="retCurrentChange"
											:current-page="currentPage2"
											:total="memberBacktableDatatotal"
										></el-pagination>
									</div>
								</div>
							</template>
						</div>
						<div v-if="memberBacktableDatatotal==0" class="tc m-b-75">
							<img class="w-200 h-150 m-t-100 c-9" src="../../assets/img/sc-1.png" >
							<p>暂无数据</p>
						</div>
						<!-- 发货填写快递弹窗 -->
						<div class="deliverloagings">
							<el-dialog title="请选填写发货信息" :visible.sync="deliverloaging">
								<div class="p-l-20 p-r-20">
									<el-form status-icon label-width="70px" class="demo-ruleForm">
										<el-form-item label="快递公司" prop="name" class="relative">
											<el-input type="text" v-model="express.company" placeholder="请填写快递公司名称"></el-input>
										</el-form-item>

										<el-form-item label="快递单号" prop="name" class="relative">
											<el-input type="text" v-model="express.single" placeholder="请填写快递单号"></el-input>
										</el-form-item>
									</el-form>
								</div>

								<div class="f-14  tc absolute b-0 m-t-20 w100 l-0 p-20 "><p class="w-100 rad-2 h-34 l-h-34 bg-00B c-f cursor m-a" @click="sendout(1)">发货</p></div>
							</el-dialog>
						</div>
					</div>
				</div>
			</div>
		</div>
	
		<orderinfo v-if="alldetail == 2" :detail="detail" :active="active" @ret="alldetail = 1" />
		<returngoods v-if="alldetail == 3" :detail="detail" @ret="alldetail = 1" />
		<paytype :paytypedialog="paytypedialog" :tradeid="payTradeid"/>
		<invoices v-if="alldetail == 4" :detail="detail" @ret="alldetail = 1" :active="active"/>
	</div>
</template>

<script>

import invoices from './invoices.vue';
import orderinfo from './orderinfo.vue';
import returngoods from './returngoods.vue';
import paytype from '../../components/paytype.vue'
var companycode = sessionStorage.getItem('companycode');
export default {
	components: { orderinfo, returngoods,paytype,invoices},
	data() {
		companycode = sessionStorage.getItem('companycode');
		return {
			menuitemAactive: '', //会员中心显示的id
			activeName: 'second1', // 显示当前页面的状态
			backviewshow: false, //显示退款页面
			alldetail: 1, //全部订单的详情
			isreturnprv: '', //首页进来返回首页的开关
			indexcount: '', //审批次数
			retidnumber: '', //退货单号退货订单详情页面
			companycode: companycode, //不传则查询用户个人数据， 传 ""查询全部， 传医院编码查询具体医院订单
			// // 退货管理----------------------------------------------------
			nav: ['全部订单', '退货申请单', '退货中', '已完成'],
			active: 0,

			ret: {
				'-2': '退货审核失败',
				'-1': '退货申请中',
				0: '退货申请成功',
				1: '退货中',
				2: '已退货',
				3: '退款中',
				4: '已完成',
				10: '待一级管理员审核',
				20: '待业务员审核',
				30: '待同步到线下',
				40: '待分公司审核',
				55:'部分发货'
			},
			shenhe: {
				'-2': '审核失败',
				'-1': '审核中',
				0: '审核成功'
			},
			rowinfo: [],
			// ismainadmin: ismainadmin,
			flag: '1,2,3,4,5,6,7',
			//1= 待付款、2= 已付款待发货、3= 已付款已发货、4= 交易完成 6= 退货中, 7= 已退货

			// ---------------------------------------------------------------------------------
			allorderlist: null, //全部订单列表
			currentPage1: 1, //全部订单的当前页码
			currentPage2: 1, //退货订单的当前页码
			currentPage3: 1, //退货申请的当前页码
			currentPage4: 1, //详情的当前页码
			currentPageshow: false, //详情是否允许分页
			pageno: 1,
			pagesize: 10,
			alllistdetailArr: [], //全部订单的查看详情商品列表
			total: 0,
			memberBacktableData: [], //退货订单
			memberBacktableDatatotal: 0, //退货订单分页总数
			retlhdh: '', //输入的来货单号
			retpihao: '', //输入的退货批号
			retqty: '', //输入的退货数量

			tradeid: '', //订单编号
			// 订单公司：
			options: [{ value: '选项1', label: '黄金糕' }],
			laiyuanyiyuan: '', //选中的订单公司
			// 订单公司改变类名
			optionProps: {
				value: 'regionno',
				label: 'name',
				children: 'childrens'
			},
			paytypedialog: false, //支付方式弹窗
			paytype: [], //获取的支付方式
			selectpaytypekey: 0, // 当前选中的支付方式
			tradeinfo: null, //订单详情的收货地址信息
			retinfo: null, //退货详情的收货地址信息
			deliverloaging: false, //是否显示填写快递单号弹窗
			express: {
				company: '', //输入的快递公司
				single: '' //输入的快递单号
			},
			retid: '',
			// paytype:['无', '微信支付', '无', '支付宝', '无', '信用付', "现金支付", '', "线下转账"],
			downloadurl: '',
			detail: '', //点前点击的item
			flegs: [],
			payTradeid:''
		};
	},

	created: function created() {
		this.orderlist();
		this.backlist();
	},
	computed: {
		isFollow() {
			this.flegs = {
				'-1': '已删除',
				'-2': '审核失败',
				'-3': '销售员审核失败',
				'-4': '分公司审核失败',
				2: this.$store.state.app.Operational.member.Tobedelivered, //'待发货',
				3: this.$store.state.app.Operational.member.Delivered, //'已发货',
				4: '交易完成',
				1: this.$store.state.app.Operational.member.Tobepaid, // '待付款',
				5: '交易关闭',
				6: '退货中',
				7: '已退货',
				11: '管理员审核',
				12: '销售员审核',
				13: '待分公司审核',
				20: '待分公司审核',
				30: '待分公司审核',
				55:'部分发货'
			};
		}
	},
	watch: { isFollow() {} },

	methods: {
		memberBackhandleSizeChange: function memberBackhandleSizeChange() {},
		memberBackhandleCurrentChange: function memberBackhandleCurrentChange(tab, event) {
			this.currentPage1 = tab;
			this.orderlist(tab);
		},

		// 	// 详情商品分页
		// 	memberBackdetal: function memberBackdetal(tab, event) {
		// 		this.currentPage4 = tab;

		// 		if (this.active == 0) {
		// 			this.currentPageshow = true;
		// 			this.alllistdetail(this.tradeid, 'look', '');
		// 		}

		// 		if (this.active != 0) {
		// 			this.currentPageshow = true;
		// 			this.retdetailgoods(this.retid, '', 'look', '');
		// 		}
		// 	},

		// 退货订单列表
		retCurrentChange: function retCurrentChange(tab, event) {
			this.currentPage2 = tab;
			this.backlist(tab);
		},

		retdetailgoods(item) {
			this.detail = item;
			this.alldetail = 2;
		},

		// 	// 全部订单列表里的详情
		alllistdetail(item, type) {
			this.detail = item;
			if (type == 'look') this.alldetail = 2;
			if (type != 'look') this.alldetail = 3;
		},
		
		seeinvoice(item){
			this.detail = item;
			this.alldetail = 4;
			console.log(this.alldetail)
		},

		//全部订单详情-------------------
		alldetailfn: function alldetailfn() {
			this.alldetail = 2;
		},

		// 点击申请退货   // 退货申请页面点击返回
		backdetailfn: function backdetailfn() {
			this.retgoods = [];
			this.backviewshow = !this.backviewshow;
		},

		// --点击nav导航 -1= 待付款、2= 已付款待发货、3= 已付款已发货、4= 交易完成 6= 退货中, 7= 已退货--------------------------------------------
		btnactive: function btnactive(index) {
			this.active = index;
			switch (index) {
				case 0:
					// this.flag = '1,2,3,4,5,6';
					this.flag = '';
					this.orderlist();
					break;
				case 1:
					this.flag = '-2,-1,0';
					this.backlist();
					break;
				case 2:
					this.flag = '1,2,3';
					this.backlist();
					break;

				case 3:
					this.flag = '4';
					this.backlist();
					break;
			} // this.tradeinfo = null

			this.currentPage1 = 1;
			this.currentPage2 = 1;
		},

		// 获取全部订单列表
		orderlist: function orderlist() {
			var self = this;
			var Url = '/trade/list';
			var data = {
				flags: this.flag,
				companycode: this.companycode,
				pageno: this.currentPage1,
				pagesize: 10
			};

			this.requset(Url, data).then(data => {
				for (var i in data.data.rows) {
					// data.data.rows[i].flag = self.flegs[data.data.rows[i].flag];
					data.data.rows[i].paytype = self.paytype[data.data.rows[i].paytypeid];
				}
				self.total=data.data.total
				self.allorderlist = data.data;
			});
		},

		

		// 获取退货列表	POST 开发完成 /b2bret/list
		backlist: function backlist() {
			var self = this;
			var Url = '/b2bret/list';
			var data = {
				flags: this.flag,
				companycode: this.companycode,
				pageno: this.currentPage2,
				pagesize: this.pagesize
			};

			this.requset(Url, data).then(function(data) {
				var result = data.data.list;
				for (var i in result) {
					result[i].flag = self.ret[result[i].flag];
					result[i].retqty = parseInt(result[i].retqty).toFixed(0);
					result[i].buyqty = parseInt(result[i].buyqty).toFixed(0);
					result[i].retdate = result[i].retdate.slice(0, 16);
					result[i].tradedate = result[i].tradedate.slice(0, 16);
					result[i].batchno = result[i].batchno != 'defaultbno' ? result[i].batchno : '默认分配';
				}
				self.memberBacktableData = result;
				self.memberBacktableDatatotal = data.data.total;
			});
		},

		// 点击发货
		retgoodssuccess: function retgoodssuccess(retid) {
			console.log(retid);
			this.deliverloaging = true;
			this.retid = retid;
		},

		// 填写快递单号发货 POST 开发完成 /trade/retexpress
		sendout: function sendout() {
			var _this3 = this;
			var Url = '/b2bret/retexpress';
			var data = {
				expressname: this.express.company,
				expressno: this.express.single,
				retid: this.retid
			};

			this.requset(Url, data)
				.then(function(data) {
					_this3.deliverloaging = false;
					_this3.$notify({ title: '成功', message: '提交成功', type: 'success' });
					_this3.backlist();
				})
				.catch(function(res) {
					_this3.$notify.error({ title: '错误', message: err.message });
				});
		},

		// 订单完成POST 开发完成 /trade/finish
		wcOrder(tradeId) {
			this.wcOrders(tradeId, res => {
				this.orderlist();
			});
		},

		//弹窗支付方式
		showpayment: function showpayment(tradeid) {
			console.log(tradeid)
			this.paytypedialog = true;
			this.payTradeid = tradeid;
		},

		// 选中来源医院
		elselectYY: function elselectYY(e) {
			this.companycode = e;

			if (this.active == 0 || this.active == 3) {
				this.orderlist();
			} else {
				this.backlist();
			}
		},

		// 下载批检报告
		download: function download() {
			this.downloadurl = downloadExcel('/trade/batchgoodsdata', this.tradeid);
		}
	}
};
</script>

<style>
	
/* 	.el-table__row--striped{background: #e5ffff;} */
	
</style>
