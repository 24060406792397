<template>
	
	<!-- 意见反馈 -->
	<div class="memberProposal " style="box-sizing:unset">
	    <li class="mright" style="margin: auto;">
	        <div class="">
	            <h3 class=" bg-f h-50 l-h-50 bg-f p-l-20 title f-18 bold"><i class="shu m-r-5"></i> 投诉建议</h3>
	            <!-- <div class="opinion_top m-b-10 bg-f h-50 l-h-50">
	
	            </div> -->
	            <form action="#" method="post" id="form1" class="bg-f p-t-10 m-t-10 p-b-10">
	                <div class="opinion_content" style="box-sizing:unset">
	                    <div class="Leave_shell" style="box-sizing:unset">
	                        <div class="Leave_type" style="box-sizing:unset">
	                            <ul>
	                                <li><span class="red">*</span> 留言类型：</li>
	                                <li class="lcursor m-r-15 m-l-5 cursor" v-for="(item,index) in list" :key="index" @click="inquire(item.id)">
	                                    <!-- <span class="pay_list_c1 " :class="item.id==type?'on':''"  > -->
										<span class="iconfont" :class="item.id==type?'b2b-danxuan c-b14e':'b2b-a-danxuanweixuanzhong'"></span>
										<input class="ir advise" type="radio" checked="checked" name="paylist" value="1"></span>{{item.label}}
	                                </li>
	                               
	                            </ul>
	                        </div>
	                        <div class="Leave_content" style="box-sizing:unset">
	                            <ul>
	                                <li><span class="red">*</span> 留言内容：</li>
	                                <li>
	                                    <textarea id="content" class="leave_textarea p-10" v-model="content" placeholder-class="placeholder"
	                                        placeholder="请填写留言内容"></textarea>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                    <div class="contact_shell">
	                        <div class="contact_nshell">
	                            <p class="tips bold" style="text-align: left; margin-top:10px ;box-sizing:unset">请留下您的联系方式，方便我们联系您<br></p><br>
	                            <p class="l-h-35 m-b-15">
	                                <label class="phone"><span class="red">*</span>联系电话：</label>
	                                <input type="text"v-model="phone" class="phone_input" maxlength="11"
	                                    placeholder="请填写真实电话号码">
	
	                            </p>
	                            <p class="l-h-35">
	                                <label class="people"><span class="red">*</span>联系人：</label>
	                                <input type="text" v-model="contacter" class="phone_input" placeholder="请填写联系人">
	
	                            </p>
	                        </div>
	                    </div>
						
						<div class="contact_shell m-b-30">
						    <div class="contact_nshellimg  ">
						        <p class="tips m-b-10 m-t-10" >上传图片</p>
						      
								<el-upload
								  ref="upload"
								  :auto-upload="false"
						          action="#"
						          list-type="picture-card"
						          :on-preview="handlePictureCardPreview"
								  :on-change="onchangeupload"
								  :limit="5"
						          :on-remove="handleRemove">
						          <i class="el-icon-plus"></i>
						        </el-upload>
						    </div>
							<el-dialog :visible.sync="dialogVisible" width="30%">
							  <img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</div>
	                    <div class="submit_leave m-b-20 ">
	                        <ul>
	                            <li class="leava_end">
	                                <img src="../../assets/img/ts.png" class="relative t-3">
	                                <label style="line-height:30px">标记为红星的信息为必填项，以上信息属于个人隐私，本站承诺绝不向第三方透漏。</label>
	                            </li>
	                            <li class="submit_manage w-120  h-60">
	                                <a href="javascript:void(0)" style="width:120px" @click="submit">确认提交</a>
	                            </li>
	                        </ul>
	                    </div>
							<div class="h-60"></div>
	                </div>
	            </form>
	        </div>
	    </li>
	</div>
</template>



<script>
		var openid = sessionStorage.getItem('openid');
	export default{
		data(){
			return{
				type:1,
				list:[
					{id:1,label:'订单问题'},
					{id:2,label:'退换货问题'},
					{id:3,label:'质量问题'},
					{id:4,label:'积分问题'},
					{id:5,label:'客服问题'},
					{id:6,label:'其他'},	
				],
				content:'',
				phone:'',
				contacter:'',
				
				dialogImageUrl: '',
				dialogVisible: false,
				fileLists:'',
				
				

			}
		},
		
		methods:{
			inquire(id){
				this.type=id
			},
			
			handleRemove(file, fileList) {
			  console.log(file, fileList);
			},
			handlePictureCardPreview(file){
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			
			// 上传的临时图片
			onchangeupload(file,fileLists) {
					console.log(file,fileLists)
					
					if(this.fileLists.length<4)this.fileLists=fileLists
			},
			
			upload() {
				var {fileLists}=this
				var uploadimg=[]
				return	new Promise((resolve, reject)=>{
					 
						fileLists.filter((res,index)=>{
							setTimeout(()=>{
								console.log('循环',res)
								var formData = new FormData();
								formData.append('file', res.raw);
								this.uploadrequset('/file/uploadimg', formData).then(data=>{
									if (data.status == 'success') {
										uploadimg.push(data.data)
										console.log(uploadimg)
										if(index==fileLists.length-1) resolve(uploadimg)
									}else {
										this.$notify.error({title: '错误',message: '上传失败,请求稍后再试',position: 'top-content'});
									}
							},index*5000)
						})
						
					})
			  });
			},
			
			

			
			submit() {
				
				var {content,phone,contacter,type}=this
				 var re = /^1\d{10}$/;
				 
				 console.log(re.test(phone))
				if (!re.test(phone)) {
					this.$notify({title: '提示',message: '手机号格式错误',type: 'error',duration:2000});
					return ;
				}
			
				if (content == '' || phone == '' || contacter == '') {
					this.$notify({title: '提示',message: '请填写好相关信息',type: 'error',duration:2000});
					return ;
				}
				
				this.upload().then(res=>{
					console.log('resresresresresresresresres',res)
					var data = {
						type: type,
						content: content,
						phone: phone,
						linkname: contacter,
						imgurls:res.join(',')
					};
					
					
					this.requset('/complaint/add', data).then(res=>{
							if (res.status == 'success') {
								this.$notify({title: '提示',message: '提交成功',type: 'success',duration:2000});
								this.content=''
								this.phone=''
								this.contacter=''
								this.$refs.upload.clearFiles()
							} 
							
						
						});
				})
			
				
			
			
				
			}
		}
		
		
	}
	
</script>

<style>
	.el-upload-list__item{
		padding: 0 !important;
	}
	.advise{width: 5px;}
	
	.contact_nshellimg{
		height: 190px;
		margin-left: 105px;
		overflow: hidden;
	}
</style>

<style scoped>
	.contact_nshellimg  >>>.el-dialog__wrapper{    overflow: hidden;    height: 157px}
	>>>.el-dialog__header{padding: 45px 20px 10px;display: none;}
	>>>.el-dialog{border-radius: 20rpx;}
	>>>.el-dialog__body{padding: 20px;}
</style>
