<template>
	<div class="mandata" id="mandata">
	    <h3 class="bg-f p-l-20 title  f-18 bold"><i class="shu m-r-5"></i> 我的资料</h3>
	    <div class="mandata-c bg-f m-t-10 p-b-20 relative">
	
	
	        <p class="c-b14e f-14 absolute right-30 top-30 cursor" @click="edit" v-if="changedisabled"><i class="iconfont">&#xe69a;</i>编辑
	        </p>
	        <div class="touxiang tc relative">

	            <el-upload
	                action="https://jsonplaceholder.typicode.com/posts/"
	                list-type="picture-card"
	                :file-list="fileList"
	                :disabled="changedisabled"
	                :on-change="handlePictureCardPreview"
	                :auto-upload="false">

				<div class="bg-0-04 h-30 l-h-30 absolute b-0 l-0 w100 c-f " v-if="!changedisabled">编辑</div>
	            </el-upload>
	        </div>
	
	        <div class="form">
	            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
	                class="demo-ruleForm">
	                <el-form-item label="用户名" prop="username">
	                    <el-input v-model="ruleForm.username" :disabled="changedisabled"></el-input>
	                </el-form-item>
	                <el-form-item label="账号">
	                    <el-input v-model.number="ruleForm.cardno" disabled></el-input>
	                </el-form-item>
	                <el-form-item label="手机号">
	                    <el-input v-model.number="ruleForm.phone" disabled></el-input>
	                </el-form-item>
	                <el-form-item label="密码" prop="pass">
	                    <el-input type="password" v-model="ruleForm.pass" :disabled="!checkPass" autocomplete="off"
	                        placeholder="请输入原密码">
	                    </el-input>
	                    <span class="changepass" @click="changepass" v-if="!changedisabled">修改密码</span>
	                </el-form-item>
	
	                <el-form-item label="新密码" prop="newpass" v-if="checkPass">
	                    <el-input type="password" placeholder="请输入新密码" v-model="ruleForm.newpass" autocomplete="off">
	                    </el-input>
	                </el-form-item>
	
	                <el-form-item label="确认密码" prop="checkPass" v-if="checkPass">
	                    <el-input type="password" placeholder="再次输入密码" v-model="ruleForm.checkPass" autocomplete="off">
	                    </el-input>
	                </el-form-item>
	
	                <el-form-item label="公司编码">
	                    <el-input v-model.number="ruleForm.b2bcompanycode" disabled></el-input>
	                </el-form-item>
	
	                <el-form-item label="归属公司">
	                    <el-input v-model="ruleForm.companyname" disabled></el-input>
	                </el-form-item>
	                <el-form-item v-if="!changedisabled">
	                    <el-button @click="resetForm('ruleForm')" class="c-b14e">取消</el-button>
	                    <el-button type="primary" @click="submitForm('ruleForm')">
	                        提交
	                    </el-button>
	                </el-form-item>
	            </el-form>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default{
		data(){
		
			var _this = this; // 我的资料的验证
		
			var checkusername = function checkusername(rule, value, callback) {
				if (!value) {
					return callback(new Error('名称不能为空'));
				} else {
					callback();
				}
			};
		
			var validatePass = function validatePass(rule, value, callback) {
				if (value === '') {
					callback(new Error('请输入旧密码'));
				} else {
					callback();
				}
			};
		
			var validatenewpass = function validatenewpass(rule, value, callback) {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (_this.ruleForm.checkPass !== '') {
						_this.$refs.ruleForm.validateField('checkPass');
					}
		
					callback();
				}
			};
		
			var validatePass2 = function validatePass2(rule, value, callback) {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== _this.ruleForm.newpass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
		
			return {
				menuitemAactive: '',
				// 我的资料start-------------------------------------
				dialogImageUrl: '',
				changedisabled: true,
				//允许修改
				fileList: [{
					url: ''
				}],
				///头像
				disabled: false,
				ruleForm: {
					username: '',
					cardno: '',
					//账号
					phone: '',
					//账号
					pass: '',
					newpass: '',
					checkPass: '',
					prtcompanycode: '',
					//上级编码
					b2bcompanycode: '',
					//公司编码
					companyname: '',
					//公司
					mandata: []
				},
				checkPass: false,
				rules: {
					pass: [{validator: validatePass,trigger: 'blur'}],
					newpass: [{validator: validatenewpass,trigger: 'blur'}],
					checkPass: [{validator: validatePass2,trigger: 'blur'}],
					username: [{validator: checkusername,trigger: 'blur'}]
				} 
		
			}
		},
		created() {
			this.getmandata()
			
		},
		
		methods:{
			// 获取个人资料数据POST 开发完成 /b2bmembercard/getb2bmembercard
			getmandata() {
				var self = this;
				var Url = '/b2bmembercard/getb2bmembercard';

				this.requset(Url, {}).then(data=>{
					this.authorization(data)
					if (data.status = 'success') {
						
						console.log(data);
						self.ruleForm.mandata = data.data;
						self.ruleForm.username = data.data.cardname;
						self.ruleForm.cardno = data.data.cardno;
						self.ruleForm.phone = data.data.phone;
						self.ruleForm.pass = data.data.openid;
						self.ruleForm.b2bcompanycode = data.data.b2bcompanycode;
						self.ruleForm.companyname = data.data.companyname;
						self.ruleForm.prtcompanycode = data.data.prtcompanycode;
						sessionStorage.setItem('cardname', data.data.cardname);
						if (data.data.iconpath) {
							self.fileList = [{
								url: data.data.iconpath
							}];
							sessionStorage.setItem('iconpath', data.data.iconpath);
						} else {
							self.fileList = [{
								url:require( '../../assets/img/user.png')
							}];
						}
					} else {
						self.$message.error('错了哦', data.message);
					}
				});	
			},
			// 个人资料提交表单验证
			submitForm: function submitForm(formName) {
				var self = this;
				this.$refs[formName].validate(function(valid) {
					if (valid) {
						self.sendmandata();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			
			// 修改的数据发送
			sendmandata: function sendmandata() {
				var _this2 = this;
			
				var self = this;
				var _this$ruleForm = this.ruleForm,
					newpass = _this$ruleForm.newpass,
					pass = _this$ruleForm.pass,
					username = _this$ruleForm.username,
					prtcompanycode = _this$ruleForm.prtcompanycode;
			
				if (!this.checkPass) {
					newpass = '';
					pass = '';
				} else {
					newpass = hex_md5(newpass).toUpperCase();
					pass = hex_md5(pass).toUpperCase();
				}
			
				var self = this;
				var Url = '/b2bmembercard/updateb2bmembercard';
				var data = {
					newpwd: newpass,
					cardname: username,
					oldpwd: pass,
					prtcompanycode: prtcompanycode,
				};
				
				
				this.requset(Url, data).then(function(data) {
					self.$notify({title: '成功',message: '修改成功',type: 'success'});
					sessionStorage.setItem('cardname', username);
					_this2.resetForm();
					_this2.getmandata();
				}, function(err) {
					self.$notify.error({title: '错误',message: err.message,position: 'top-content'});
				});
					
			},
			
			
			// 点击修改密码
			changepass: function changepass() {
				this.checkPass = true;
				this.ruleForm.pass = '';
			},
			
			// 点击取消修改
			resetForm: function resetForm() {
				this.checkPass = false;
				this.changedisabled = true;
				var mandata = this.ruleForm.mandata;
				this.ruleForm.username = mandata.cardname;
				this.ruleForm.cardno = mandata.cardno;
				this.ruleForm.phone = mandata.phone;
				this.ruleForm.prtcompanycode = mandata.prtcompanycode;
				this.ruleForm.pass = "******";
				this.ruleForm.newpass = '';
				this.ruleForm.checkPass = '';
				this.ruleForm.company = mandata.channelname;
				this.$refs['ruleForm'].clearValidate(); //请除验证
			},
			
			
			
			// ----------我的资料上传头像--------------------------------------------------------------------------
			handlePictureCardPreview(fileLists) {
				var _this3 = this;
				var self = this;
				
				this.$confirm('您将使用该头像', '提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					closeOnClickModal: false,
					customClass:this.color,

					callback:function(action){
						if(action=='confirm'){
							sessionStorage.setItem('iconpath', fileLists.url);
							console.log(fileLists);
							_this3.fileList = [];
							_this3.fileList.push(fileLists);
							var formData = new FormData();
							formData.append('headimage', fileLists.raw);
							var url = '/b2bmembercard/editheadimage';
							self.uploadrequset(url, formData).then(data=>{
								console.log(data)
								if (data.status == 'success') {
									_this3.$notify({title: '上传成功',message: '您的图片已上传成功',type: 'success'});
								} else if (data.status == 'fail') {
									self.$notify.error({title: '错误',message: data.message,position: 'top-content'});
								}
							});
							
						}
					}
				})
			},
			// 点击编辑
			edit: function edit() {
				this.changedisabled = false;
			}
		}
	}
</script>

<style>
	.el-button--primary:hover{color: #fff !important;}
</style>
